import { useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from 'antd'
import React from 'react'
import { setLoginRedirect } from '../utils/Callback'

const SignInMessage = ({ title, description, signInOnly }) => {
  const { loginWithRedirect } = useAuth0()
  const location = useLocation()

  const handleLogin = (screen_hint) => {
    setLoginRedirect(location.pathname)
    loginWithRedirect({ screen_hint })
  }

  return (
    <div className="sign-in-message">
      <h2>{title}</h2>
      <p>{description}</p>
      <div className="inline-spacing">
        <Button onClick={() => handleLogin('login')}>
          Sign in
        </Button>
        {!signInOnly && <Button onClick={() => handleLogin('signup')}>
          Sign up
        </Button>}
      </div>
    </div>
  )
}

export default SignInMessage
