import { Auth0Provider } from '@auth0/auth0-react'
import App from '../react/components/App'
import { render } from 'react-dom'
import RedBox from 'redbox-react'
import React from 'react'

const Application = () => (
  <Auth0Provider
    domain={process.env['AUTH0_DOMAIN']}
    clientId={process.env['AUTH0_CLIENT_ID']}
    redirectUri={`${window.location.origin}/callback`}
    audience={process.env['AUTH0_AUDIENCE']}
    scope="openid profile email"
    useRefreshTokens
    cacheLocation="localstorage"
  >
    <App />
  </Auth0Provider>
)

document.addEventListener('DOMContentLoaded', () => {
  const reactElement = document.getElementById('app')

  if (reactElement) {
    if(window.railsEnv && window.railsEnv === 'development'){
      try {
        render(<Application />, reactElement)
      } catch (e) {
        render(<RedBox error={e} />, reactElement)
      }
    }
    else {
      render(<Application />, reactElement)
    }
  }
})
