import { BracketPeriod, getBracketPeriod } from '../contexts/RootContext'
import { fetchLocalBrackets } from '../utils/utils'
import { useNavigate } from 'react-router-dom'
import PageLoading from '../misc/PageLoading'
import React, { useEffect } from 'react'

export default function The18Redirects() {
  const navigate = useNavigate()

  useEffect(() => {
    if ([BracketPeriod.Open, BracketPeriod.SecondChance].includes(getBracketPeriod())) {
      const hasBracket = Boolean(fetchLocalBrackets()[0])
      navigate(hasBracket ? '/the18/leaderboard' : '/the18/create')
    } else {
      navigate('/the18/leaderboard')
    }
  }, [])

  return <PageLoading />
}
