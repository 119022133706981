import React, { useEffect } from 'react'

export const AdSlots = {
  BracketFooter: '5801250380',
  HomeHeader: '6929476408',
  LeaderboardHeader: '4319586949',
  LeaderboardFooter1: '9964351518',
  LeaderboardFooter2: '1501851912',
  LeaderboardFooter3: '7875688576',
}

const displayAds = process.env['DISPLAY_ADS']

export default function AdSense({ slot }) {
  useEffect(() => {
    displayAds && (window.adsbygoogle = window.adsbygoogle || []).push({});

    // remove style tag on unmount
    return () => {
      document.getElementsByClassName('main-routes-container')?.[0]?.setAttribute('style', '')
    }
  }, [])

  return (<>
    {displayAds && <ins className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-7747840977810912"
          data-ad-slot={slot}
          data-ad-format="auto"
          data-full-width-responsive="true"
    />}
  </>)
}
