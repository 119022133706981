import { Line } from '@ant-design/plots'
import { colors } from '../utils'
import React from 'react'

export default function LineTotalStats({ data, total }) {

  const config = {
    data: [...data, ...total],
    padding: 'auto',
    xField: 'dateLabel',
    yField: 'total',
    seriesField: 'type',
    slider: {
      start: .765,
      end: 1,
    },
    theme: {
      colors10: colors
    }
  };

  return <>
    <h2>Total to date</h2>
    <Line {...config} />
  </>
}
