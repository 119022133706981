import { Table, Tooltip } from 'antd'
import React, { useLayoutEffect, useState } from 'react'

const useWindowWidth = () => {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default function SelectionTable({ count, data, secondChance }) {
  const windowWidth = useWindowWidth();

  const smallScreen = windowWidth < 815
  const calcWidth = (windowWidth * 0.6) / 6
  const width = calcWidth < 30 ? 30 : calcWidth > 80 ? 80 : calcWidth
  const percent = (number) => {
    const num = number / count
    if (num === 0) return '0%'
    if (num < 0.001) return '<0.1%'
    if (num < 0.01) return `${Math.round(num * 1000) / 10}%`
    if (num === 1) return '100%'
    if (num > 0.99) return '99%'
    return `${Math.round(num * 100)}%`
  }
  const cellClass = (number) => {
    const num = Math.floor((number / count) * 10)
    return { className: `fill-${num}` }
  }

  const columns = [
    {
      key: 'country',
      title: 'Country',
      dataIndex: 'country',
      className: secondChance ? 'second-chance-country-column' : 'country-column',
      sorter: (a, b) => a.country.localeCompare(b.country),
      render: (entry) => {
        return ( smallScreen
          ? <Tooltip placement="right" title={entry}>
            <img src={`/flags/${entry}.png`} alt={entry}/>
          </Tooltip>
          : <>
            <img src={`/flags/${entry}.png`} alt={entry}/>
            {entry}
          </>
        )
      }
    }, ...(!secondChance ? [{
      key: 'knockout',
      title: smallScreen ? 'Advance' : 'Advance to knockout',
      dataIndex: 'knockout',
      width,
      render: percent,
      onCell: (entry) => cellClass(entry.knockout),
      sorter: (a, b) => b.knockout - a.knockout,
    }, {
      key: 'win_group',
      title: 'Win group',
      dataIndex: 'win_group',
      width,
      render: percent,
      onCell: (entry) => cellClass(entry.win_group),
      sorter: (a, b) => b.win_group - a.win_group,
    }] : []), {
      key: 'quarters',
      title: smallScreen ? 'Quarters' : 'Quarter finals',
      dataIndex: 'quarters',
      width,
      render: percent,
      onCell: (entry) => cellClass(entry.quarters),
      sorter: (a, b) => b.quarters - a.quarters,
    }, {
      key: 'semis',
      title: smallScreen ? 'Semis' : 'Semi finals',
      dataIndex: 'semis',
      width,
      render: percent,
      onCell: (entry) => cellClass(entry.semis),
      sorter: (a, b) => b.semis - a.semis,
    }, {
      key: 'finals',
      title: 'Finals',
      dataIndex: 'finals',
      width,
      render: percent,
      onCell: (entry) => cellClass(entry.finals),
      sorter: (a, b) => b.finals - a.finals,
    }, {
      key: 'winner',
      title: 'Winner',
      dataIndex: 'winner',
      width,
      defaultSortOrder: 'ascend',
      render: percent,
      onCell: (entry) => cellClass(entry.winner),
      sorter: (a, b) => b.winner - a.winner,
    },
  ]

  return (
    <div className="selection-table">
      <h2>{`${secondChance ? 'Second Chance ' : ''}Brackets: ${count}`}</h2>
      <Table
        rowKey="country"
        dataSource={data}
        columns={columns}
        pagination={false}
        sticky
      />
    </div>
  )
}
