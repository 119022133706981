import { BracketPeriod, useRootContext } from '../contexts/RootContext'
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useLayoutEffect } from 'react'
import BackButton from '../misc/BackButton'
import GroupCard from './GroupCard'
import { Button } from 'antd'

const pickMap = {
  a1: 9,
  a2: 14,
  b1: 1,
  b2: 6,
  c1: 13,
  c2: 10,
  d1: 5,
  d2: 2,
  e1: 11,
  e2: 16,
  f1: 3,
  f2: 8,
  g1: 15,
  g2: 12,
  h1: 7,
  h2: 4,
}

export const pickName = (groupName, position) => {
  return `pick_${pickMap[`${groupName}${position}`]}`
}

export default function GroupForm(props) {
  const { bracket, rankings, scorecard, setBracket, setStep, editing } = props
  const { groups } = scorecard
  const { bracketPeriod } = useRootContext()

  useLayoutEffect(() => {
    if (bracketPeriod === BracketPeriod.SecondChance) {
      setStep(1)
    }
  }, [])

  const validateBracket = () => {
    for (let i = 1; i < 17; i++) {
      if (!bracket[`pick_${i}`]) return false
    }
    return true
  }

  return (
    <div className="bracket-group-form">
      {editing
        ? <p className="name-edit-message">Bracket name cannot be edited</p>
        : <BackButton onClick={() => setStep(1)} text="Registration"/>
      }
      <div className="groups-header">
        <h1 className="wc-font">Group Stage</h1>
        <p className="wc-font header-description">Select the first and second place finishers from each group.</p>
      </div>
      <div className="groups-container">
        {Object.entries(groups).map(([name, group]) => {
          let first
          let second
          const teams = group.sort((a, b) => a.localeCompare(b)).map(country => rankings[country])
          const firstTeam = bracket[pickName(name, 1)]
          if (firstTeam) {
            const firstIdx = teams.findIndex(team => team.name === firstTeam)
            if (firstIdx !== -1)
              first = teams.splice(firstIdx, 1)[0]
          }
          const secondTeam = bracket[pickName(name, 2)]
          if (secondTeam) {
            const secondIdx = teams.findIndex(team => team.name === secondTeam)
            if (secondIdx !== -1)
              second = teams.splice(secondIdx, 1)[0]
          }

          return <GroupCard
            key={name}
            name={name}
            first={first}
            second={second}
            group={teams}
            bracket={bracket}
            setBracket={setBracket}
          />
        })}
      </div>
      <div className="groups-footer">
        <Button disabled={!validateBracket()} type="primary" onClick={() => {
          setStep(3)
          window.scrollTo(0, 0);
        }}>
          Continue to Knockout Stage&nbsp;
          <FontAwesomeIcon icon={faAnglesRight} />
        </Button>
      </div>
    </div>
  )
}
