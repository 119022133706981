import { CLOSE_DATE, OPEN_DATE, SECOND_CLOSE_DATE, SECOND_OPEN_DATE, WC_NAME } from '../utils/Constants'
import React, { createContext, useContext, useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from './AuthContext'
import PageLoading from '../misc/PageLoading'

export const BracketPeriod = Object.freeze({
    Closed: "closed",
    Group: 'group',
    Preview: "preview",
    Open: "open",
    SecondChance: "second-chance",
})

const RootContext = createContext({
    bracketPeriod: BracketPeriod.Closed
})

export const getBracketPeriod = () => {
    const now = Date.now()

    if (now < Date.parse(OPEN_DATE.computer)) {
        return BracketPeriod.Preview
    } else if (now < Date.parse(CLOSE_DATE.computer)) {
        return BracketPeriod.Open
    } else if (now < Date.parse(SECOND_OPEN_DATE.computer)) {
        return BracketPeriod.Group
    } else if (now < Date.parse(SECOND_CLOSE_DATE.computer)) {
        return BracketPeriod.SecondChance
    } else {
        return BracketPeriod.Closed
    }
}

export const RootContextProvider = ({ children }) => {
    const [initialized, setInitialized] = useState(false)

    // root context
    const [bracketPeriod, setBracketPeriod] = useState(getBracketPeriod())

    // state context
    const [pool, setPool] = useState(null)
    const [bracketsLoadError, setBracketsLoadError] = useState(null)
    const [bracketsLoaded, setBracketsLoaded] = useState(false)
    const [bracketsLoading, setBracketsLoading] = useState(false)
    const [primaryBrackets, setPrimaryBrackets] = useState([])
    const [primaryPagy, setPrimaryPagy] = useState({})
    const [secondChanceBrackets, setSecondChanceBrackets] = useState([])
    const [secondChancePagy, setSecondChancePagy] = useState({})

    const { authorizationHeaders, isAdmin, poolToken, userState } = useAuthContext()
    const navigate = useNavigate()

    useLayoutEffect(() => {
        if (userState === undefined) return

        const done = (newPool) => {
            setPool(newPool)
            if (location.search.includes('pool') || location.href === `${location.origin}/`)
                navigate('/leaderboard')
            setTimeout(() => setInitialized(true), 100)
        }

        if (poolToken) {
            const userPool = userState?.pools.find(p => p.token === poolToken)
            if (userPool) {
                done(userPool)
            } else {
                fetch(encodeURI(`/api/v1/pool?token=${poolToken}`), {
                    credentials: 'same-origin',
                    headers: authorizationHeaders,
                })
                  .then(response => {
                      if (response.ok) {
                          return response.json();
                      } else {
                          throw(response);
                      }
                  })
                  .then(body => {
                      done(body)
                  })
                  .catch(() => {
                      const poolName = location.search.match(/pool=(.+):/)?.[1]
                      if (poolName){
                          navigate(`/pools?tab=enter&name=${poolName}`)
                      }
                      localStorage.removeItem('PoolToken')
                      setInitialized(true)
                  })
            }
        } else if (userState?.pools.length) {
            done(userState.pools[0])
        } else {
            setInitialized(true)
        }
    }, [userState])

    const handleSetBracketPeriod = (value) => {
        if (isAdmin) {
            setBracketPeriod(value)
        }
    }

    const handlePoolChange = (poolObject = undefined) => {
        if (poolObject) {
            setPool(poolObject)
            localStorage.setItem('PoolToken', poolObject.token)
        } else {
            setPool(null)
            localStorage.removeItem('PoolToken')
        }
        navigate(poolObject ? '/leaderboard' : '/')
        setBracketsLoaded(false)
        setPrimaryBrackets([])
        setSecondChanceBrackets([])
    }

    const fetchLeaderboard = (secondChance, search = '', page = 1, pageSize = 100, sort = 'rank', order = 'ascend') => {
        setBracketsLoading(true)
        const pagination = `page=${page}&pageSize=${pageSize}&sort=${sort}&order=${order}&search=${search}&secondChance=${secondChance}`
        fetch(encodeURI(`/api/v1/leaderboard/${WC_NAME}${pool ? `?pool=${pool.id}&${pagination}` : `?${pagination}`}`), {
            credentials: 'same-origin',
            headers: authorizationHeaders,
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw(response);
            }
        })
        .then(body => {
            setPrimaryBrackets(body.primary)
            setSecondChanceBrackets(body.second_chance)
            setBracketsLoaded(true)
            setBracketsLoading(false)
            setPrimaryPagy(body.primary_pagy)
            setSecondChancePagy(body.second_chance_pagy)
        })
        .catch(error => {
            setBracketsLoadError(error)
            setBracketsLoading(false)
        })
    }

    const value = {
        bracketPeriod,
        bracketsLoadError,
        bracketsLoaded,
        bracketsLoading,
        fetchLeaderboard,
        handlePoolChange,
        pool,
        primaryBrackets,
        primaryPagy,
        secondChanceBrackets,
        secondChancePagy,
        setBracketPeriod: handleSetBracketPeriod,
        setBracketsLoaded,
        setPool,
    }

    return (
        <RootContext.Provider value={value}>
            {initialized ? children : <PageLoading />}
        </RootContext.Provider>
    )
}

export function useRootContext() {
    return useContext(RootContext)
}
