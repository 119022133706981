import '@ant-design/flowchart/dist/index.css'

import { MetricsChannel } from '../../channels/metrics_channel'
import GroupedColumnStats from './Charts/GroupedColumnStats'
import React, { useEffect, useMemo, useState } from 'react'
// import PoolBracketChart from './Charts/PoolBracketChart'
import { useAuthContext } from '../contexts/AuthContext'
import LineTotalStats from './Charts/LineTotalStats'
import { eachDayOfInterval, format } from 'date-fns'
import { removeWhiteSpace } from '../utils/utils'
import TotalsTable from './Charts/TotalsTable'
import PageLoading from '../misc/PageLoading'
import { WC_NAME } from '../utils/Constants'
import Forbidden from '../Error/Forbidden'
import { formatDate } from './utils'

const buildSeries = (source, type) => {
  let total = 0
  const ordered = Object.keys(source).sort().reduce((obj, key) => {
    obj[key] = source[key]
    return obj
  }, {})
  return Object.entries(ordered).map(([date, count]) => {
    return {
      type,
      dateLabel: formatDate(date),
      date: new Date(date),
      count: count,
      total: total += count
    }
  })
}

const todayTotal = (source, type) => {
  let total = 0
  Object.values(source).forEach(count => total += count)
  return {
    type,
    dateLabel: formatDate(),
    date: new Date(),
    total: total
  }
}

export default function Metrics() {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [brackets, setBrackets] = useState({})
  const [pools, setPools] = useState({})
  const [users, setUsers] = useState({})
  const [reload, setReload] = useState(null)
  const [isReloading, setIsReloading] = useState(false)

  const { authorizationHeaders, isAdmin } = useAuthContext()

  useEffect(() => {
    MetricsChannel(setReload)
  }, [isAdmin])
  
  useEffect(() => {
    let timeout = 0

    if (isAdmin && !isReloading) {
      setIsReloading(true)
      fetch(encodeURI('/api/v1/metrics'), {
        credentials: 'same-origin',
        headers: authorizationHeaders,
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw(response);
        }
      })
      .then(body => {
        setBrackets(body.brackets)
        setPools(body.pools)
        setUsers(body.users)
        setLoading(false)
        timeout = setTimeout(() => {
          setIsReloading(false)
        }, 300000)
      })
      .catch(error => {
        setError(error)
      })
    } else {
      setLoading(false)
    }

    return () => timeout && clearTimeout(timeout)
  }, [isAdmin, reload])

  const bracketSeries = useMemo(() => {
    return buildSeries(brackets, 'brackets')
  }, [brackets])

  const poolSeries = useMemo(() => {
    return buildSeries(pools, 'pools')
  }, [pools])

  const userSeries = useMemo(() => {
    return buildSeries(users, 'users')
  }, [users])

  const allDates = useMemo(() => {
    return eachDayOfInterval({
      start: new Date('06/24/2019'),
      end: new Date()
    }).map(date => {
      return { dateLabel: format(date, 'MM/dd/yyyy'), date: new Date(date) }
    })
  }, [])

  if (loading || isAdmin === null) {
    return <PageLoading />
  }

  if (!isAdmin || error) {
    return <Forbidden />
  }

  const data = [...bracketSeries, ...poolSeries, ...userSeries, ...allDates]
  const totalObjects = [
    todayTotal(brackets, 'brackets'),
    todayTotal(pools, 'pools'),
    todayTotal(users, 'users'),
  ]
  return (
    <div className={`stats-container ${removeWhiteSpace(WC_NAME)}`}>
      <h1 className="wc-primary">Metrics</h1>
      <TotalsTable brackets={brackets} pools={pools} users={users} days={allDates.length} />
      <GroupedColumnStats title="Daily" data={data} />
      <GroupedColumnStats colorIdx={0} title="Brackets" data={[...bracketSeries, ...allDates]}/>
      <GroupedColumnStats colorIdx={1} title="Pools" data={[...poolSeries, ...allDates]}/>
      <GroupedColumnStats colorIdx={2} title="Users" data={[...userSeries, ...allDates]}/>
      <LineTotalStats data={data.sort((a, b) => a.date - b.date)} total={totalObjects}/>
      {/*<PoolBracketChart pools={pools} />*/}
    </div>
  )
}
