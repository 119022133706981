import { BracketPeriod, useRootContext } from '../contexts/RootContext'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuthContext } from '../contexts/AuthContext'
import BracketForm from '../BracketForm/BracketForm'
import { useToast } from '../contexts/ToastContext'
import React, { useEffect, useState } from 'react'
import { removeWhiteSpace } from '../utils/utils'
import PageLoading from '../misc/PageLoading'
import { WC_NAME } from '../utils/Constants'
import Error from '../Error/Error'

export default function BracketEditForm() {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [bracket, setBracket] = useState({})

  const { authorizationHeaders, userState } = useAuthContext()
  const { errorToast, successToast } = useToast()
  const { bracketPeriod } = useRootContext()
  const navigate = useNavigate()
  const { id } = useParams()

  const canEdit = bracketPeriod === BracketPeriod.Open && userState?.brackets.find(bracket => bracket.id === id)

  useEffect(() => {
    if (canEdit) {
      fetch(`/api/v1/brackets/${id}`, {
        credentials: 'same-origin',
        headers: authorizationHeaders,
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw(response);
        }
      })
      .then(body => {
        setBracket(body.bracket)
        setLoading(false)
      })
      .catch(error => {
        setError(error)
        setLoading(false)
      })
    } else {
      setError({ status: 403 })
      setLoading(false)
    }
  }, [userState?.brackets])

  if (loading) {
    return <div className={removeWhiteSpace(WC_NAME)}>
      <div className="background-image bracket-form-background"/>
      <PageLoading />
    </div>
  }
  if (error) return <Error error={error} />

  const updateBracket = (bracket) => {
    fetch(`/api/v1/brackets/${id}`, {
      method: "PUT",
      body: JSON.stringify(bracket),
      credentials: 'same-origin',
      headers: authorizationHeaders,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw(response);
      }
    })
    .then(() => {
      navigate(`/brackets/${id}`)
      successToast('Bracket saved')
    })
    .catch(error => {
      errorToast('Changes could not be saved at this time.')
      return error.json()
      .then(message => {
        errorToast(`${error.status} (${message.error})`)
      })
    });
  }

  return <BracketForm
    editing={bracket}
    updateBracket={updateBracket}
    bracketPeriod={bracketPeriod}
    authorizationHeaders={authorizationHeaders}
  />
}
