import React from 'react'
import PolicyLinks from './misc/PolicyLinks'

export default function Footer() {
  return (
    <div className="footer">
      <span><PolicyLinks /></span>
      <span>Copyright © 2023 Dichroic Software, LLC. All rights reserved.</span>
      <span>
        <a href='https://ko-fi.com/E1E2EPJ2T' target='_blank' rel="noopener noreferrer">
          Buy the developer coffee
          <img src='https://storage.ko-fi.com/cdn/brandasset/kofi_s_logo_nolabel.png' alt='Buy Me a Coffee at ko-fi.com' />
        </a>
      </span>
    </div>
  )
}
