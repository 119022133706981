import { BracketPeriod, getBracketPeriod, useRootContext } from '../contexts/RootContext'
import { validateUniqueEmail } from '../The18/utils'
import { removeWhiteSpace } from '../utils/utils'
import SignInMessage from '../misc/SignInMessage'
import { useAuth0 } from '@auth0/auth0-react'
import { Button, Form, Input } from 'antd'
import { Link } from 'react-router-dom'
import React from 'react'

export default function NameForm(props) {
  const { the18, name, email, form, phone, scorecard, setEmail, setName, setPhone, setStep, validateUniqueName } = props
  const { isAuthenticated } = useAuth0()
  const { pool } = useRootContext()

  return (
    <div className="new-bracket-form">
      <div className="form-container white-box-shadow">
        <h1 className={removeWhiteSpace(scorecard.name)}>{the18 ? 'Create a tournament bracket' : `Create a bracket for ${scorecard.name}`}</h1>
        {!the18 && !isAuthenticated && <SignInMessage
          title="Brackets are better with an account."
          description="Creating an account is optional, but it will allow you to edit your bracket and keep track of your pools."
        />}
        {!the18 && <div className="header-description">
          <p>{`This bracket will be created in the ${pool?.name || 'public'} pool.`}</p>
          {!pool && <p>Want to start a pool? <Link to="/pools?tab=create">Create a private pool.</Link></p>}
        </div>}
        <Form
          form={form}
          layout="vertical"
          initialValues={{ name, email, phone }}
          onFinish={() => {
            setName(form.getFieldValue('name'))
            setEmail(form.getFieldValue('email'))
            setPhone(form.getFieldValue('phone'))
            setStep(getBracketPeriod() === BracketPeriod.SecondChance ? 3 : 2)
          }}
          autoComplete="off"
          requiredMark="optional"
          validateTrigger={the18 ? 'onSubmit' : 'onChange'}
        >
          <div className="the18-form-group">
            <Form.Item
              label="Bracket name"
              name="name"
              rules={[{ required: true }, { validator: validateUniqueName }]}
            >
              <Input placeholder="Bracket name"/>
            </Form.Item>
            {(the18 || !isAuthenticated) && <Form.Item
              label={the18 ? <>Email&nbsp;<span className="form-optional">(required)</span></> : 'Email'}
              name="email"
              rules={the18 ? [{ required: true }, { validator: validateUniqueEmail }] : []}
            >
              <Input data-private="lipsum" placeholder="email"/>
            </Form.Item>}
            {the18 && <Form.Item
              label={<>Cell phone&nbsp;<span className="form-optional">(optional, receive deals on gear from adidas and Niky's Sports)</span></>}
              name="phone"
            >
              <Input data-private="lipsum" placeholder="phone"/>
            </Form.Item>}
          </div>
          {the18 && <span className="the18-notice">By entering your email, you are opting in to receive communications from The18 and our partners on this promotion. We respect your privacy and do not share your information.</span>}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Next
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}
