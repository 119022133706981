export const the18AuthHeaders = {
  'Accept': 'application/json',
  'Authorization': '',
  'Content-Type': 'application/json',
  'developer-token': '',
}

export const validateUniqueEmail = async (_rule, value) => {
  const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/
  if (emailRegex.test(value)) {
    const response = await fetch(`/api/v1/the18/email`, {
      method: 'POST',
      body: JSON.stringify({ email: value }),
      credentials: 'same-origin',
      headers: the18AuthHeaders
    })
    if (response.ok) {
      return Promise.resolve();
    } else {
      return Promise.reject('Only one bracket can be created per email address.')
    }
  } else {
    return Promise.reject('Please enter a valid email address.')
  }
}
