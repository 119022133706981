import { Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'


export default function PickScore(props) {
  const { bracket, handleChange, readonly, setValidScore } = props
  const { winner_score, loser_score } = bracket
  const [form] = Form.useForm()
  const [error, setError] = useState('')

  useEffect(() => {
    if (readonly) return
    if (winner_score && loser_score && !error) {
      setValidScore(true)
    } else {
      setValidScore(false)
    }
  }, [winner_score, loser_score, error])

  const onFieldsChange = (fields, allFields ) => {
    const { value, name } = fields[0]
    if (!value) {
      setError('Both scores are required.')
      return
    }

    const number = parseInt(value)
    if (isNaN(number) && Number.isInteger(number) && number >= 0) {
      setError('Scores must be positive integers.')
      return
    }

    if (name[0] === 'winner_score') {
      if (loser_score && number <= parseInt(loser_score)) {
        setError('Winning score must be higher than losing score. (The final score is full time including outcome after penalties.)')
        return
      }
    } else if (name[0] === 'loser_score') {
      if (winner_score && number >= winner_score) {
        setError('Losing score must be lower than winning score. (The final score is full time including outcome after penalties.)')
        return
      }
    }
    setError('')
    saveValidScores(allFields)
  }

  const saveValidScores = (allFields) => {
    allFields.forEach(field => {
      const { value, name } = field
      handleChange(value, name[0])
    })
  }

  return (
      <Form
        form={form}
        layout="vertical"
        initialValues={{ winner_score, loser_score }}
        validateTrigger="onChange"
        autoComplete="off"
        requiredMark={false}
        onFieldsChange={onFieldsChange}
      >
        <div className="round-details primary wc-font wc-secondary" >
          Final tiebreaker
        </div>
        <div className="bracket-final-score">
          <Form.Item
            label="Winning score"
            name="winner_score"
            className="date wc-quaternary"
          >
            <Input disabled={readonly} placeholder="Winner"/>
          </Form.Item>
          <Form.Item
            label="Losing score"
            name="loser_score"
            className="date wc-quaternary"
          >
            <Input disabled={readonly} placeholder="Loser"/>
          </Form.Item>
        </div>
        {error && <div className="score-tiebreaker-error">{error}</div>}
      </Form>
  )
}
