import ShareButtons from '../utils/ShareButtons'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'antd'
import React from 'react'

export default function BracketSavedModal({ bracket, the18 }) {
  const navigate = useNavigate()
  const title = the18 ? 'The18 World Cup Bracket Challenge' : 'Check out my World Cup bracket!'
  const message = the18
    ? 'Just filled out my World Cup bracket! Enter yours for a chance to win great prizes.'
    : 'Just filled out my WORLD CUP bracket at bracketchallenge.world! Who\'s your pick to win it all?'
  const shareUrl = the18
    ? 'https://the18.com/world-cup-bracket-challenge'
    : `${location.origin}/brackets/${bracket?.id}`
  const hashtags = the18
    ? ['the18soccer', 'worldcup', 'bracketchallenge', 'adidas']
    : ['worldcup', 'bracketchallenge']

  return (
    <Modal
      title={`Bracket Saved!`}
      className={`${the18 ? 'the-18' : ''} bracket-saved-modal`}
      visible={!!bracket}
      onCancel={() => navigate(`${the18 ? '/the18' : ''}/brackets/${bracket?.id}`)}
      cancelText="Go to bracket"
      okText="Back to leaderboard"
      onOk={() => navigate(`${the18 ? '/the18' : ''}/leaderboard`)}
      closable={false}
      maskClosable={false}
      keyboard={false}
      maskStyle={{ opacity: 0 }}
    >
      <p>Your bracket has been saved! Want to share it with the world?</p>
      <ShareButtons
        title={title}
        message={message}
        url={shareUrl}
        hashtags={hashtags}
        the18Direct={the18 ? `${shareUrl}?bracket=${bracket?.id}`: undefined}
      />
    </Modal>
  )
}
