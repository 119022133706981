import { CLOSE_DATE, OPEN_DATE, SECOND_CLOSE_DATE, SECOND_OPEN_DATE, TIME_ZONE, WC_NAME } from './utils/Constants'
import { BracketPeriod, useRootContext } from './contexts/RootContext'
import { useLocation, useNavigate } from 'react-router-dom'
import PoolPasswordModal from './Pools/PoolPasswordModal'
import { useAuthContext } from './contexts/AuthContext'
import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Menu, Tooltip } from 'antd'
import { setLoginRedirect } from './utils/Callback'

const createBracketMessage = (bracketPeriod) => {
  switch (bracketPeriod) {
    case BracketPeriod.Preview:
      return `Brackets open ${OPEN_DATE.human} and close ${CLOSE_DATE.human} ${TIME_ZONE}`

    case BracketPeriod.Group:
      return `Second chance brackets open ${SECOND_OPEN_DATE.human} and close ${SECOND_CLOSE_DATE.human} ${TIME_ZONE}`

    case BracketPeriod.Closed:
      return 'Brackets for this tournament are closed'

    default:
      return null
  }
}

const statsMessage = bracketPeriod => {
  switch (bracketPeriod) {
    case BracketPeriod.Group:
      return null

    case BracketPeriod.SecondChance:
      return 'Stats available once the knockout stage begins.'

    case BracketPeriod.Closed:
      return null

    default:
      return 'Stats available once the tournament begins.'
  }
}

const getPathKey = (path) => {
  return path.substring(1) || 'home'
}

export default function MainMenu() {
  const location = useLocation()
  const navigate = useNavigate()
  const { isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0()
  const { bracketPeriod, handlePoolChange, pool, setBracketPeriod, setBracketsLoaded } = useRootContext()
  const { isAdmin, userState } = useAuthContext()
  const isPoolOwner = pool?.owner.includes(userState?.user.sub) ?? false

  const [current, setCurrent] = useState(getPathKey(location.pathname))
  const [showPoolPassword, setShowPoolPassword] = useState(false)

  useEffect(() => {
    setCurrent(getPathKey(location.pathname))
  }, [location.pathname])

  const handleClick = ({ key }) => {
    if (key.startsWith('bracket-period')) {
      setBracketPeriod(key.replace('bracket-period/', ''))
    } else if (key.startsWith('pools/switch')) {
      const poolId = key.replace('pools/switch/', '')
      handlePoolChange(userState.pools.find(pool => pool.id === parseInt(poolId)))
    } else {
      switch (key) {
        case 'home':
          navigate('/')
          break
        case 'pools/leave':
          handlePoolChange()
          break
        case 'pools/password':
          setShowPoolPassword(true)
          break
        case 'login':
          setLoginRedirect(location.pathname)
          loginWithRedirect()
          break
        case 'logout':
          localStorage.clear()
          navigate('/')
          logout()
          break
        default:
          navigate(`/${key}`);
      }
    }
  }

  const createOption = {
    key: 'create',
    label: <Tooltip placement="bottom" title={createBracketMessage(bracketPeriod)}>
      Create bracket
    </Tooltip>,
    disabled: ![BracketPeriod.Open, BracketPeriod.SecondChance].includes(bracketPeriod)
  }

  const myPools = userState?.pools.length && { key: 'pools/switch', label: 'My pools', children: [
    ...userState.pools.map(pool => ({ key: `pools/switch/${pool.id}`, label: pool.name })),
    { key: 'pools', label: 'Create or join another pool' },
  ]}

  const items = [
    { key: 'home', label: 'Home' },
    { key: 'leaderboard', label: 'Leaderboard' },
    pool
      ? {
        key: 'pools/menu',
        label: `Pool: ${pool.name}`,
        children: [
          myPools,
          isPoolOwner && { key: 'pools/password', label: 'Change password' },
          { key: 'pools/leave', label: 'Log out of pool' },
        ] }
      : myPools || { key: 'pools', label: 'Pools' },
    userState?.brackets?.length ? { key: 'brackets', label: 'My brackets', children: [
        ...userState.brackets.map(bracket => ({ key: `brackets/${bracket.id }`, label: bracket.name })),
        createOption,
    ] } : createOption,
    { key: 'faq', label: 'FAQ' },
    { key: 'archive', label: 'Archive', children: [
      pool?.name === 'FWC Production' && { key: 'archive/france', label: 'France 2019' },
      { key: 'archive/qatar', label: 'Qatar 2022' },
    ] },
    {
      key: 'stats',
      label: <Tooltip placement="bottom" title={statsMessage(bracketPeriod)}>
        Stats
      </Tooltip>,
      disabled: !(isAdmin || [BracketPeriod.Group, BracketPeriod.Closed].includes(bracketPeriod))
    },
    isAdmin && { key: 'metrics', label: 'Metrics' },
    !isLoading && { key: isAuthenticated ? 'logout' : 'login', label: isAuthenticated ? 'Sign out' : 'Sign in' },
    isAdmin && { key: 'bracket-period', label: 'Bracket period', children: [
      { key: `bracket-period/${BracketPeriod.Preview}`, label: 'Preview' },
      { key: `bracket-period/${BracketPeriod.Open}`, label: 'Open' },
      { key: `bracket-period/${BracketPeriod.Group}`, label: 'Group' },
      { key: `bracket-period/${BracketPeriod.SecondChance}`, label: 'Second chance' },
      { key: `bracket-period/${BracketPeriod.Closed}`, label: 'Closed' },
    ] },
  ]

  return (<>
    <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" theme="dark" items={items} />
    {isPoolOwner && <PoolPasswordModal visible={showPoolPassword} setVisible={setShowPoolPassword}/>}
  </>)
}
