import { useAuthContext } from '../contexts/AuthContext'
import React, { useEffect, useState } from 'react'
import { removeWhiteSpace } from '../utils/utils'
import PageLoading from '../misc/PageLoading'
import { WC_NAME } from '../utils/Constants'
import { useParams } from 'react-router-dom'
import Error from '../Error/Error'
import Bracket from './Bracket'

export default function ShowBracket() {
  const [bracket, setBracket] = useState(null)
  const [standings, setStandings] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const { authorizationHeaders } = useAuthContext()
  const { id } = useParams()

  useEffect(() => {
    fetch(`/api/v1/brackets/${id}`, {
      credentials: 'same-origin',
      headers: authorizationHeaders,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw(response);
      }
    })
    .then(body => {
      setBracket(body.bracket)
      if (body.standings) {
        setStandings(body.standings)
      }
      setLoading(false)
    })
    .catch(error => {
      setError(error)
      setLoading(false)
    })
  }, [authorizationHeaders, id])

  if (loading) {
    return <div className={removeWhiteSpace(WC_NAME)}>
      <div className="background-image bracket-form-background"/>
      <PageLoading />
    </div>
  } else if (error) {
    return <Error error={error} />
  }

  return <Bracket bracket={bracket} standings={standings} showBackButton />
}
