import React from 'react';
import { Select } from "antd";

export default function FormTile(props) {
  const { disabled, field, label, mobileRight, onChange, options, value } = props

  const selectOptions = options.map(option => {
    const { name, rank, flag } = option
    return (
      <Select.Option value={name} key={name} title={name}>
        <img className="inline-flag" src={flag} alt={'flag'} />
        {name}
        <span className="wc-ranking">{rank ? `RK: ${rank}` : ''}</span>
      </Select.Option>
    )
  })

  return(
    <Select
      disabled={disabled}
      allowClear
      notFoundContent="Selection required in previous round(s)"
      className="team-form-select"
      placeholder={label}
      placement={mobileRight ? 'bottomRight' : 'bottomLeft'}
      onChange={(v, _f) => onChange(v, field)}
      value={value}
      id={field}
      dropdownMatchSelectWidth={false}
    >
      {selectOptions}
    </Select>
  )
}
