import { copyToClipboard } from './utils'
import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'

export default function ShareButtons(props) {
  const [copied, setCopied] = useState(false)
  const { message, title, url, hashtags, the18Direct } = props

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 3000)

      return () => clearTimeout(timer)
    }
  }, [copied])

  return (
    <>
      <FacebookShareButton url={url} quote={message} hashtag={hashtags[0]}>
        <Button><FacebookIcon size={20}/>Share on Facebook</Button>
      </FacebookShareButton>
      <RedditShareButton url={url} title={message}>
        <Button><RedditIcon size={20}/>Share on Reddit</Button>
      </RedditShareButton>
      <TelegramShareButton url={url} title={message}>
        <Button><TelegramIcon size={20}/>Share on Telegram</Button>
      </TelegramShareButton>
      <TumblrShareButton url={url} title={title} caption={message} tags={hashtags}>
        <Button><TumblrIcon size={20}/>Share on Tumblr</Button>
      </TumblrShareButton>
      <TwitterShareButton url={url} title={message} hashtags={hashtags}>
        <Button><TwitterIcon size={20}/>Share on Twitter</Button>
      </TwitterShareButton>
      <WhatsappShareButton url={url} title={message}>
        <Button><WhatsappIcon size={20}/>Share on Whatsapp</Button>
      </WhatsappShareButton>
      <EmailShareButton url={url} subject={title} body={message}>
        <Button><EmailIcon size={20}/>Email</Button>
      </EmailShareButton>
      <Button className="direct-link-button" onClick={() => copyToClipboard(the18Direct ?? url, setCopied)}>
        {copied ? 'Copied' : 'Copy direct link to clipboard'}
      </Button>
    </>
  )
}
