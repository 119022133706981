import { Result } from 'antd'

import React from 'react'

export default function Unavailable({ title, subTitle}) {

  return (
    <div className="error-background">
      <Result
        status="warning"
        title={title ?? 'Unavailable'}
        subTitle={subTitle ?? 'Sorry, we\'re having trouble loading this page right now. Please try again later.'}
      />
    </div>
  )
}
