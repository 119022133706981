export const countries = {
  'Argentina': 'ARG',
  'Australia': 'AUS',
  'Belgium': 'BEL',
  'Brazil': 'BRA',
  'Cameroon': 'CMR',
  'Canada': 'CAN',
  'Chile': 'CHI',
  'China PR': 'CHN',
  'Colombia': 'COL',
  'Costa Rica': 'CRC',
  'Croatia': 'CRO',
  'Denmark': 'DEN',
  'Ecuador': 'ECU',
  'England': 'ENG',
  'France': 'FRA',
  'Germany': 'GER',
  'Ghana': 'GHA',
  'Haiti': 'HAI',
  'IR Iran': 'IRN',
  'Italy': 'ITA',
  'Jamaica': 'JAM',
  'Japan': 'JPN',
  'Korea Republic': 'KOR',
  'Mexico': 'MEX',
  'Morocco': 'MAR',
  'Netherlands': 'NED',
  'New Zealand': 'NZL',
  'Nigeria': 'NGA',
  'Norway': 'NOR',
  'Panama': 'PAN',
  'Philippines': 'PHI',
  'Poland': 'POL',
  'Portugal': 'POR',
  'Qatar': 'QAT',
  'Republic of Ireland': 'IRL',
  'Saudi Arabia': 'KSA',
  'Scotland': 'SCO',
  'Senegal': 'SEN',
  'Serbia': 'SRB',
  'South Africa': 'RSA',
  'Spain': 'ESP',
  'Sweden': 'SWE',
  'Switzerland': 'SUI',
  'Thailand': 'THA',
  'Tunisia': 'TUN',
  'USA': 'USA',
  'Uruguay': 'URU',
  'Vietnam': 'VIE',
  'Wales': 'WAL',
  'Zambia': 'ZAM',
}
