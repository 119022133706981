import { BracketPeriod, useRootContext } from '../contexts/RootContext'
import LeaderboardDescription from './LeaderboardDescription'
import LeaderboardAlert from '../Error/LeaderboardAlert'
import { Link, useNavigate } from 'react-router-dom'
import { useToast } from '../contexts/ToastContext'
import AdSense, { AdSlots } from '../misc/AdSense'
import { removeWhiteSpace } from '../utils/utils'
import { WC_NAME } from '../utils/Constants'
import Leaderboard from '../Leaderboard'
import React, { useEffect } from 'react'
import Error from '../Error/Error'
import { Button } from 'antd'

const CreateButton = ({ bracketPeriod, navigate }) => {
  if (bracketPeriod === BracketPeriod.Open) {
    return (
      <div className="create-button-container">
        <Button type="primary" className="create-button-center" onClick={() => navigate('/create')}>Create bracket</Button>
      </div>
    )
  } else if (bracketPeriod === BracketPeriod.SecondChance) {
    return (
      <div className="create-button-container">
        <Button type="primary" className="create-button-center" onClick={() => navigate('/create')}>Create second chance bracket</Button>
      </div>
    )
  } else return null
}

export const Leaderboards = (props) => {
  const {
    fetchThe18Leaderboard,
    the18,
    loading,
    bracketPeriod,
    primaryBrackets,
    primaryPagy,
    secondChanceBrackets,
    secondChancePagy,
    name = 'Brackets'
  } = props

  switch (bracketPeriod) {
    case BracketPeriod.Open:
      return (
        <Leaderboard fetchThe18Leaderboard={fetchThe18Leaderboard} the18={the18} loading={loading} readOnly name={name} brackets={primaryBrackets} pagy={primaryPagy}/>
      )

    case BracketPeriod.Group:
      return (
        <Leaderboard fetchThe18Leaderboard={fetchThe18Leaderboard} the18={the18} loading={loading} name={name} brackets={primaryBrackets} pagy={primaryPagy}/>
      )

    case BracketPeriod.SecondChance:
      return (
        <div className="double-leaderboard">
          <Leaderboard fetchThe18Leaderboard={fetchThe18Leaderboard} the18={the18} loading={loading} name={name} brackets={primaryBrackets} pagy={primaryPagy}/>
          <Leaderboard fetchThe18Leaderboard={fetchThe18Leaderboard} the18={the18} loading={loading} readOnly name="Second Chance" brackets={secondChanceBrackets} pagy={secondChancePagy}/>
        </div>
      )
    default:
      return (
        <div className="double-leaderboard">
          <Leaderboard fetchThe18Leaderboard={fetchThe18Leaderboard} the18={the18} loading={loading} name={name} brackets={primaryBrackets} pagy={primaryPagy}/>
          <Leaderboard fetchThe18Leaderboard={fetchThe18Leaderboard} the18={the18} loading={loading} name="Second Chance" brackets={secondChanceBrackets} pagy={secondChancePagy}/>
        </div>
      )
  }
}

export default function Home() {
  const {
    bracketPeriod,
    bracketsLoadError,
    bracketsLoaded,
    bracketsLoading,
    fetchLeaderboard,
    pool,
    primaryBrackets,
    primaryPagy,
    secondChanceBrackets,
    secondChancePagy,
  } = useRootContext()

  const { infoToast } = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    if (bracketPeriod === BracketPeriod.Preview) {
      infoToast(`${WC_NAME} is coming soon. Displaying leaderboard for Qatar 2022`)
      navigate('/archive/qatar')
    } else if (!bracketsLoaded && !bracketsLoading) {
      fetchLeaderboard()
    }
  }, [bracketsLoaded, bracketPeriod])

  const LeaderboardContents = () => {
    if (bracketsLoadError) return <Error error={bracketsLoadError} />
    else return (
      <Leaderboards
        primaryPagy={primaryPagy}
        loading={bracketsLoading}
        bracketPeriod={bracketPeriod}
        primaryBrackets={primaryBrackets}
        secondChancePagy={secondChancePagy}
        secondChanceBrackets={secondChanceBrackets}
      />
    )
  }
  return (
    <>
      <div className="background-image leaderboard-background"/>
      <div className={`leaderboard-container ${removeWhiteSpace(WC_NAME)}`}>
        <AdSense slot={AdSlots.LeaderboardHeader} />
        <div className="header-container white-box-shadow">
          <h1 className="leaderboard wc-font wc-primary">{`${pool?.name || ''} Leaderboard`}</h1>
          {process.env['LEADERBOARD_ALERT_MESSAGE'] && <LeaderboardAlert message={process.env['LEADERBOARD_ALERT_MESSAGE']}/>}
          <div className="header-description wc-font">
            <LeaderboardDescription />
            {bracketPeriod === BracketPeriod.Open && !pool && <p>Want to play with friends? <Link to="/pools?tab=create">Create a private pool.</Link></p>}
          </div>
          <CreateButton bracketPeriod={bracketPeriod} navigate={navigate} />
        </div>
        {pool?.image_url && <div>
          <img className="pool-logo" src={pool.image_url} alt="pool logo"/>
        </div>}
        {LeaderboardContents()}
        <AdSense slot={AdSlots.LeaderboardFooter1} />
        <AdSense slot={AdSlots.LeaderboardFooter2} />
        <AdSense slot={AdSlots.LeaderboardFooter3} />
      </div>
    </>
  )
}
