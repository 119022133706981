import { formatDuration, intervalToDuration } from 'date-fns'
import React, { useEffect, useState } from 'react'


export default function Countdown({ className, endDate, text }) {
  const duration = () => {
    return intervalToDuration({
      start: new Date(),
      end: new Date(endDate),
    })
  }

  const [time, setTime] = useState(duration())

  useEffect(() => {
    const interval = setInterval(() => setTime(duration()), 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <p className={className ?? ''}>
      {`${text}: ${formatDuration(time, { delimiter: ', ' })}`}
    </p>
  )
}
