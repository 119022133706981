import { useAuthContext } from './contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Modal } from 'antd'
import React, { useEffect } from 'react'

export default function Admin() {
  const { isAdmin } = useAuthContext()
  const navigate = useNavigate()
  const [form] = Form.useForm()

  useEffect(() => {
    if (isAdmin === false) {
      localStorage.removeItem('AdminBearerToken')
    }
  }, [isAdmin])

  const addToken = () => {
    localStorage.setItem('AdminBearerToken', form.getFieldValue('token'))
    window.location.reload()
  }

  const removeToken = () => {
    localStorage.removeItem('AdminBearerToken')
    window.location.reload()
  }

  return (
    <Modal
      title="Administrator access"
      className="new-bracket-form"
      visible={true}
      onCancel={() => navigate('/')}
      onOk={isAdmin ? removeToken : addToken}
      okText={isAdmin ? 'Logout' : 'Login'}
      cancelText="Home"
    >
      {isAdmin
        ? <p>Logged in as administrator</p>
        : <Form
          form={form}
          layout="vertical"
          initialValues={{ token: '' }}
          autoComplete="off"
          requiredMark="optional"
        >
          <Form.Item
            label="Administrator token"
            name="token"
            rules={[{ required: true }]}
          >
            <Input data-private="lipsum" placeholder="Token"/>
          </Form.Item>
        </Form>
      }
    </Modal>
  )
}
