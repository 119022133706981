import React from 'react'

const flagPath = (winner) => `/flags/${winner.includes('Play-off') ? 'Mystery' : winner}.png`

export default function WinnerFlag({ winner }) {
  return (
    <div className="winner-flag-container">
      {winner && <img className="winner-flag" src={flagPath(winner)} alt={winner} />}
    </div>
  )
}
