import ExternalLink from '../misc/ExternalLink'
import React from 'react'

export default function UnsplashCredit({ photo, href, text }) {
  return (
    <>
      {`${photo} photo by `}
      <ExternalLink href={href} text={text} />
      {' on '}
      <ExternalLink
        href="https://unsplash.com/@mwellman/likes?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        text="Unsplash"
      />
    </>
  )
}
