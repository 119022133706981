import { isAfter, subDays } from 'date-fns'
import { Table } from 'antd'
import React from 'react'

const rangeCount = (resources, ago) => {
  const entries = Object.keys(resources).filter(date => isAfter(new Date(date), subDays(new Date(), ago)))
  let total = 0
  entries.forEach(entry => total += resources[entry])
  return total
}

const round = (number) => Math.round(number * 100) / 100
const percent = (number) => Math.round((number) * 10000) / 100

const buildStats = (resources, days) => {
  let total = 0
  Object.values(resources).forEach(count => total += count)
  return {
    total,
    dailyAverage: total / days,
    weeklyAverage: total / (days / 7),
    thirtyDayAverage: total / (days / 30),
    lastDay: rangeCount(resources, 1),
    lastWeek: rangeCount(resources, 7),
    lastThirty: rangeCount(resources, 30),
  }
}

const buildDiff = (last, avg) => {
  let percentClass = 'neutral'
  let percentage = '0%'
  const diff = last - avg
  if (diff > 0) {
    percentClass = 'positive'
    percentage = `+${percent(diff / avg)}%`
  } else if (diff < 0) {
    percentClass = 'negative'
    percentage = `${percent(diff / avg)}%`
  }
  return <span className={`totals-diff ${percentClass}`}>{percentage}</span>
}

const columns = [
  {
    key: 'name',
    title: 'Resource',
    dataIndex: 'name',
  }, {
    key: 'total',
    title: 'Total',
    dataIndex: 'total',
  }, {
    key: 'daily',
    title: 'Daily average',
    render: (entry) => round(entry.dailyAverage)
  }, {
    key: 'last-day',
    title: 'Last day',
    render: (entry) => <>
      {round(entry.lastDay)}
      {buildDiff(entry.lastDay, entry.dailyAverage)}
    </>
  }, {
    key: 'weekly',
    title: 'Weekly average',
    render: (entry) => round(entry.weeklyAverage)
  }, {
    key: 'last-week',
    title: 'Last week',
    render: (entry) => <>
      {round(entry.lastWeek)}
      {buildDiff(entry.lastWeek, entry.weeklyAverage)}
    </>
  }, {
    key: 'thirty',
    title: 'Thirty day average',
    render: (entry) => round(entry.thirtyDayAverage)
  }, {
    key: 'last-thirty',
    title: 'Last thirty',
    render: (entry) => <>
      {round(entry.lastThirty)}
      {buildDiff(entry.lastThirty, entry.thirtyDayAverage)}
    </>
  }
]

export default function TotalsTable(props) {
  const { brackets, pools, users, days } = props

  const dataSource = [
    {
      key: 1,
      name: 'Brackets',
      ...buildStats(brackets, days),
    }, {
      key: 2,
      name: 'Pools',
      ...buildStats(pools, days),
    }, {
      key: 3,
      name: 'Users',
      ...buildStats(users, days),
    },
  ]

  return (
    <div className="totals-table">
      <h2>Totals</h2>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{ hideOnSinglePage: true }}
      />
    </div>
  )
}
