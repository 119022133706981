import { CLOSE_DATE, SECOND_CLOSE_DATE, ABBREV_TIME_ZONE } from '../utils/Constants'
import { Table } from 'antd'
import React from 'react'

const dataSource = [
  {
    key: 1,
    name: 'Round of 16',
    primary: 2,
    secondary: 0,
  }, {
    key: 2,
    name: 'Quarter finals',
    primary: 5,
    secondary: 2,
  }, {
    key: 3,
    name: 'Semi finals',
    primary: 10,
    secondary: 4,
  }, {
    key: 4,
    name: 'Finals',
    primary: 20,
    secondary: 8,
  }, {
    key: 5,
    name: 'Third place',
    primary: 20,
    secondary: 12,
  }, {
    key: 6,
    name: 'Winner',
    primary: 50,
    secondary: 16,
  }, {
    key: 7,
    name: 'Total possible',
    primary: 222,
    secondary: 76,
  },
]

const columns = [
  {
    title: undefined,
    dataIndex: 'name',
    key: 'name'
  }, {
    title: (<div className="score-table-header">
      <h3>Main brackets <span>Group Stage</span></h3>
      <p>{`Deadline: ${CLOSE_DATE.human} ${ABBREV_TIME_ZONE}`}</p>
    </div>),
    dataIndex: 'primary',
    key: 'primary'
  }, {
    title: (<div className="score-table-header">
      <h3>Second chance brackets <span>Knockout Stage</span></h3>
      <p>{`Deadline: ${SECOND_CLOSE_DATE.human} ${ABBREV_TIME_ZONE}`}</p>
    </div>),
    dataIndex: 'secondary',
    key: 'secondary'
  },
]

export default function ScoringTable() {
  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={{ hideOnSinglePage: true }}
      className="scoring-table"
    />
  )
}
