import React, { createContext, useContext, useLayoutEffect, useState } from 'react'
import { fetchLocalBrackets } from '../utils/utils'
import CookieConsent from 'react-cookie-consent'
import { useAuth0 } from '@auth0/auth0-react'
import PolicyLinks from '../misc/PolicyLinks'
import PageLoading from '../misc/PageLoading'
import { useToast } from './ToastContext'
import LogRocket from 'logrocket'

const adminToken = localStorage.getItem('AdminBearerToken')
const fetchPoolToken = () => {
  const search = new URLSearchParams(window.location.search)
  if (search.has('pool')) {
    const pool = search.get('pool')
    localStorage.setItem('PoolToken', pool)
    return pool
  }

  return localStorage.getItem('PoolToken')
}

const initialHeaders = {
  'Accept': 'application/json',
  'Authorization': '',
  'Content-Type': 'application/json',
  'developer-token': `Basic ${adminToken}`,
}

export const initLogRocket = (appName = 'yjaug1/world-cup-bracket') => {
  if (process.env.NODE_ENV !== 'development') {
    LogRocket.init(appName, {
      network: {
        requestSanitizer: request => {
          if (request.headers['Authorization']) {
            request.headers['Authorization'] = 'redacted';
          }
          if (request.headers['developer-token']) {
            request.headers['developer-token'] = 'redacted';
          }
          if (request.method === 'POST' || request.method === 'PATCH') {
            request.body = 'redacted'
          }

          return request;
        },
      },
    });
  }
}

const AuthContext = createContext({
  authorizationHeaders: initialHeaders,
  isAdmin: null,
  poolToken: '',
})

export default function AuthContextProvider({ children }) {
  const [initialized, setInitialized] = useState(false)

  // auth context
  const [isAdmin, setIsAdmin] = useState(null)
  const [poolToken] = useState(fetchPoolToken())
  const [authorizationHeaders, setAuthorizationHeaders] = useState(initialHeaders)

  // user context
  const [userState, setUserState] = useState(undefined)

  const { error, getAccessTokenSilently, isAuthenticated, isLoading, user, logout } = useAuth0()
  const { errorToast } = useToast()

  useLayoutEffect(() => {
    if (adminToken) {
      fetch(encodeURI('/api/v1/admin'), {
        credentials: 'same-origin',
        headers: authorizationHeaders,
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw(response);
        }
      })
      .then(body => {
        setIsAdmin(body.isAdmin)
      })
      .catch(() => {
        setIsAdmin(false)
      })
    } else {
      setIsAdmin(false)
      initLogRocket()
    }
  }, [])

  useLayoutEffect(() => {
    if (!isLoading) {
      if (error) {
        errorToast(`Authentication failed: ${error.message}`)
        logout()
      }
      else if (isAuthenticated) {
        LogRocket.identify(user?.sub)
        const fetchToken = async () => {
          const token = await getAccessTokenSilently()
          const newHeaders = {
            ...authorizationHeaders,
            'Authorization': `Bearer ${token}`
          }
          setAuthorizationHeaders(newHeaders)
          fetch(encodeURI('/api/v1/identify'), {
            method: 'POST',
            body: JSON.stringify({ brackets: fetchLocalBrackets() }),
            credentials: 'same-origin',
            headers: newHeaders,
          })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                throw(response);
              }
            })
            .then(body => {
              setUserState(body)
            })
            .catch(() => {
              setUserState(null)
            })
        }
        fetchToken().then(() => setInitialized(true))
      } else {
        setUserState(null)
        setInitialized(true)
      }
    }
  }, [isLoading])

  const value = {
    authorizationHeaders,
    isAdmin,
    poolToken,
    setUserState,
    userState,
  }

  return (
    <AuthContext.Provider value={value}>
      {initialized ? children : <PageLoading />}
      <CookieConsent buttonWrapperClasses="cookie-consent-actions">
        <p>Please note that this site is not affiliated with FIFA in any way.</p>
        <p>We respect your privacy. Cookies are used to operate this website, improve usability, and analyze our traffic. <PolicyLinks /></p>
      </CookieConsent>
    </AuthContext.Provider>
  )
}

export function useAuthContext() {
  return useContext(AuthContext)
}
