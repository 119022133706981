import { OPEN_DATE, SECOND_CLOSE_DATE, TIME_ZONE, WC_NAME } from '../utils/Constants'
import { BracketPeriod, useRootContext } from '../contexts/RootContext'
import React, { useLayoutEffect, useMemo, useState } from 'react'
import { useAuthContext } from '../contexts/AuthContext'
import { useToast } from '../contexts/ToastContext'
import { useNavigate } from 'react-router-dom'
import InviteModal from '../Pools/InviteModal'
import { Button, Input } from 'antd'

const { TextArea } = Input

const buildDefaultDescription = (bracketPeriod, pool) => {
  switch (bracketPeriod) {
    case BracketPeriod.Preview:
      return `Hang tight, we're still getting ready. Brackets for ${WC_NAME} can be created beginning on ${OPEN_DATE.human} ${TIME_ZONE}.`
    case BracketPeriod.Open:
      return `Any brackets that have been created in the ${pool?.name || 'public'} pool will be listed below. You will be able to see everyone's bracket and picks once the tournament begins, along with current and maximum possible score updates at the end of every match.`
    case BracketPeriod.Group:
      return `Here are all the brackets that have been created in the ${pool?.name || 'public'} pool. You can explore them all and see the picks. Current and maximum possible score updates will begin as soon as teams clinch their spot in the Knockout Stage.`
    case BracketPeriod.SecondChance:
      return `Second chance brackets are now open! Create a bracket with the 16 teams that made it to the Knockout Stage. Act fast, second chance brackets must be submitted by ${SECOND_CLOSE_DATE.human} ${TIME_ZONE}.`
    case BracketPeriod.Closed:
      return `All the brackets that have been created in the ${pool?.name || 'public'} pool. Current and maximum possible scores will be updated at the end of every match.`
  }
}


export default function LeaderboardDescription () {
  const { bracketPeriod, pool, setPool } = useRootContext()
  const { authorizationHeaders, userState, setUserState } = useAuthContext()
  const { errorToast } = useToast()
  const navigate = useNavigate()

  const defaultDescription = useMemo(() => {
    return buildDefaultDescription(bracketPeriod, pool)
  }, [bracketPeriod, pool])

  const [editing, setEditing] = useState(false)
  const [showInvite, setShowInvite] = useState(false)
  const [description, setDescription] = useState(pool?.description || defaultDescription)
  const isOwner = pool?.owner.includes(userState?.user.sub) ?? false
  const reset = () => setDescription(pool?.description || defaultDescription)

  useLayoutEffect(() => {
    reset()
  }, [pool?.description])

  const handleCancel = () => {
    setEditing(false)
    reset()
  }

  const handleSave = () => {
    fetch(`/api/v1/pools/${pool.id}`, {
      method: "PATCH",
      body: JSON.stringify({ description }),
      credentials: 'same-origin',
      headers: authorizationHeaders,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw(response);
      }
    })
    .then(body => {
      setPool(body)
      const userPools = [ ...userState.pools ]
      const idx = userPools.findIndex(pool => pool.id === body.id)
      if (idx !== -1) {
        userPools.splice(idx, 1, body)
        setUserState({
          ...userState,
          pools: userPools
        })
      }
      setEditing(false)
    })
    .catch(() => {
      reset()
      errorToast('Description could not be saved at this time.')
      setEditing(false)
    });
  }

  const Buttons = () => {
    return (
      <div className="edit-desc-buttons">
        {editing
          ? <>
            <Button onClick={handleCancel}>Discard</Button>
            <Button type="primary" onClick={handleSave}>Save changes</Button>
          </>
          : <>
            <Button onClick={() => setEditing(true)}>Edit description</Button>
            <Button onClick={() => setShowInvite(true)}>Invite friends</Button>
            <Button onClick={() => navigate(`/pools/${pool.id}/manage`)}>Manage pool</Button>
          </>
        }
      </div>
    )
  }
  return (
    <div className="pool-description">
      <TextArea
        autoSize
        allowClear={editing}
        bordered={editing}
        showCount={editing}
        maxLength={5000}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        disabled={!editing}
      />
      {isOwner && editing && <div className="spacer"/>}
      {isOwner && <Buttons />}
      {isOwner && <InviteModal visible={showInvite} setVisible={setShowInvite} />}
    </div>
  )
}
