import { initLogRocket } from '../contexts/AuthContext'
import React, { useEffect } from 'react'

const preventIndexing = () => {
  const meta = document.createElement('meta')
  meta.name = 'robots'
  meta.content = 'noindex, nofollow'
  document.getElementsByTagName('head')[0].appendChild(meta);
}

export default function The18Logging() {
  useEffect(() => {
    initLogRocket('yjaug1/the18')
    preventIndexing()
  }, [])
  return null
}
