import { getBracketPeriod } from '../contexts/RootContext'
import BracketForm from '../BracketForm/BracketForm'
import { the18AuthHeaders } from './utils'
import React from 'react'

export default function The18Create() {
  return <BracketForm
    the18
    poolId={process.env['THE_18_POOL_ID']}
    bracketPeriod={getBracketPeriod()}
    authorizationHeaders={the18AuthHeaders}
  />
}
