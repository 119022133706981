import {CLOSE_DATE, SECOND_OPEN_DATE, SECOND_CLOSE_DATE, TIME_ZONE} from '../utils/Constants'
import ExternalLink from '../misc/ExternalLink'
import UnsplashCredit from './UnsplashCredit'
import MailSupport from '../misc/MailSupport'
import ScoringTable from './ScoringTable'
import FaqListItem from './FaqListItem'
import React from 'react'

export default function Faq() {
  return (
    <div className="faq-container">
      <h1>Frequently Asked Questions</h1>
      <ol>
        <FaqListItem
          question="Any affiliation with the tournament?"
          answer="This website is not affiliated with FIFA in any way. It was created just for fun as a way to follow
          along with the tournament and compete with friends and colleagues."
        />
        <FaqListItem
          question="Who can create a bracket?"
          answer="Anyone! It's free and you don't need to create an account."
        />
        <FaqListItem
          question="Can I compete with my friends?"
          answer={'Yes! The best way to play with friends is to create a pool and invite ' +
            'everyone you\'d like to join. To create a pool, click on the "Pools" tab at the top of page, create a ' +
            'pool name and an entry password. Share that name and password with your friends, or the direct link ' +
            '(copied from the "Invite friends" button on your pool\'s leaderboard).'}
        />
        <FaqListItem
          question="What is the maximum number of people who can enter a pool?"
          answer="There is no limit to the number of people or entries in a pool, and all pools are free."
        />
        <FaqListItem
          question="When does the entry period end?"
          answer={'Brackets can be created up until the start of the Group Stage. All brackets must be submitted by ' +
            `${CLOSE_DATE.human} ${TIME_ZONE}.`}
        />
        <FaqListItem
          question="If my bracket is terrible, is there a second chance opportunity?"
          answer={`Yes! Second chance brackets open at the end of the Group Stage, ${SECOND_OPEN_DATE.human} 
          ${TIME_ZONE}, and close at the start of the Knockout Stage, ${SECOND_CLOSE_DATE.human} ${TIME_ZONE}. 
          It's a short window, so be sure to mark your calendar. At the end of the Group Stage, you have the option to
          create a new bracket with the round of 16 already filled out. These brackets are completely separate from the
          main brackets that were created prior to the Group Stage. They appear in a separate leaderboard either next to
          or under the main leaderboard depending on your device's screen size. The scoring for these brackets is
          different as well. Think of second chance brackets as a separate consolation competition.`}
        />
        <FaqListItem
          question="Who can create a second chance bracket?"
          answer="Anyone can create a second chance bracket, they do not need to already be in the pool. You can invite
          people to your pool using the same link or password as before."
        />
        <FaqListItem
          question="How does the scoring work?"
          answer="Points are awarded for each correct pick according to the table below. Note that for a pick to be
          considered correct it has to be in the correct place in the bracket, so picking the winner and
          runner-up from each group correctly really matters. So for example, say you pick Brazil to win their group
          and win the tournament, but instead they finish second in their group and then go on to win the tournament.
          In this scenario, you would not receive any points for Brazil for the early rounds, but you would receive
          points for the later rounds when they are in the correct spot in the bracket."
        />
        <ScoringTable />
        <FaqListItem
          question="A team I picked advance to the round of 16, why is it crossed out in later rounds?"
          answer={'If you picked a team to win their group but they finished as a runner-up (or vice-versa) then no ' +
            'points are awarded. The pick must be in the correct place in the bracket (it is a bracket challenge, ' +
            'after all). Points can still be awarded in the later rounds if it\'s in the correct place. For more ' +
            'information look above to "How does the scoring work?".'}
        />
        <FaqListItem
          question="When will the scores update?"
          answer="Scores recalculate at the end of every match. In the Group Stage, scores will begin to change after a
          team clinches their spot for the Knockout Stage. Typically that will happen after a team plays in their third
          Group Stage match, but can sometimes be sooner. In the Knockout Stage, scores update at the end of each match
          after a winner is determined."
        />
        <FaqListItem
          question="Why can't I see anyone else's bracket in my pool?"
          answer="You cannot see anyone else's bracket while brackets are being created before the tournament. You will
          be able to see everyone's bracket at the start of the Group Stage."
        />
        <FaqListItem
          question="Where do the 'RK' rankings come from?"
          answer={<>{'The rankings you see when you create a bracket are the latest official world rankings. '}
            <ExternalLink text="Learn more." href="https://www.fifa.com/fifa-world-ranking/procedure-women" /></>}
        />
        <FaqListItem
          question="What do I get if I win?"
          answer="Bragging rights! If you organize a pool you may establish your own prizes, but this website does not
          support sports betting and will not hand out any rewards or prizes."
        />
        <FaqListItem
          question="Can I edit my bracket before the tournament?"
          answer="Yes, an account is required to edit your bracket."
        />
        <FaqListItem
          question="Is there a way to delete a bracket?"
          answer={'Brackets can be deleted only by the pool owner. Just click the "Manage pool" button on your ' +
            'pool\'s leaderboard, or contact your pool\'s owner for assistance. Brackets created in the public pool ' +
            'cannot be deleted.'}
        />
        <FaqListItem
          question="Is there a way to delete a pool?"
          answer="Not at this time, but any pools that do not have any brackets after the tournament will be
          automatically deleted."
        />
        <FaqListItem
          question="General feedback, questions, or technical support?"
          answer={<>Send us an email at <MailSupport />. We look forward to hearing from you!</>}
        />
        <FaqListItem
          question="Credits"
          answer={<>
            <UnsplashCredit
              photo="Home"
              href="https://unsplash.com/@farzadme?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
              text="Farzad Mohsenvand"
            />
            <br />
            <UnsplashCredit
              photo="Trophy leaderboard"
              href="https://unsplash.com/es/@fznsr_?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
              text="Fauzan Saari"
            />
            <br />
            <UnsplashCredit
              photo="Created"
              href={"https://unsplash.com/@ninjason?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"}
              text="Jason Leung"
            />
            <br />
            <UnsplashCredit
              photo="Sydney leaderboard"
              href="https://unsplash.com/@liampozz?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
              text="Liam Pozz"
            />
            <br />
            <ExternalLink
              href='https://www.freepik.com/vectors/abstract-futuristic'
              text="Abstract futuristic vector created by BiZkettE1 - www.freepik.com"
            />
            <br />
            <ExternalLink
              href="https://www.freepik.com/free-vector/watercolor-blue-abstract-background_18776384.htm#page=3&query=pastels%20green%20and%20black&position=3&from_view=search&track=ais"
              text="Image by pikisuperstar on Freepik"
            />
          </>}
        />
      </ol>
    </div>
  )
}
