import { BracketPeriod, useRootContext } from '../contexts/RootContext'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useAuthContext } from '../contexts/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import { useToast } from '../contexts/ToastContext'
import { removeWhiteSpace } from '../utils/utils'
import SignInMessage from '../misc/SignInMessage'
import { useAuth0 } from '@auth0/auth0-react'
import { WC_NAME } from '../utils/Constants'
import { Form, Input, Button } from 'antd'

export default function Pools() {
  const search = new URLSearchParams(window.location.search)

  const [tab, setTab] = useState('create')
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [poolNames, setPoolNames] = useState([])
  const [error, setError] = useState(null)

  const [form] = Form.useForm()
  const { bracketPeriod, handlePoolChange } = useRootContext()
  const { authorizationHeaders, setUserState, userState } = useAuthContext()
  const { successToast } = useToast()
  const { isAuthenticated } = useAuth0()
  const navigate = useNavigate()

  const create = tab === 'create'

  const validateName = (_rule, value) => {
    if (create && poolNames.includes(value.toLowerCase())) {
      return Promise.reject('Pool name is already taken.')
    }
    const validCharacters = /^[\w\s-]*$/
    if (validCharacters.test(value)) {
      return Promise.resolve()
    } else {
      return Promise.reject('Only alphanumeric, underscore, hyphen, and space characters are permitted.')
    }
  }

  useEffect(() => {
    if (bracketPeriod === BracketPeriod.Closed && create) {
      navigate('/pools?tab=enter')
    }
  }, [])

  useLayoutEffect(() => {
    if (search.has('tab')) {
      const newTab = search.get('tab')
      if ((/^(?:create|enter)$/).test(newTab)) {
        setTab(search.get('tab'))
      }
    }
  }, [search])

  useEffect(() => {
    if (search.has('name')) {
      const name = search.get('name')
      form.setFieldValue('name', name)
      document.getElementById('password-field')?.focus()
      setButtonDisabled(false)
    }
  }, [search])

  useEffect(() => {
    if (isAuthenticated && !poolNames.length && !window.location.search.includes('enter')) {
      fetch(encodeURI('/api/v1/poolnames'), {
        credentials: 'same-origin',
        headers: authorizationHeaders
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw(response);
        }
      })
      .then(body => {
        setPoolNames(body.pools)
      })
      .catch(() => {
        // do nothing
      })
    }
  }, [create])

  const validatePassword = (_rule, value) => {
    const password = form.getFieldValue('password')
    if (password === value) {
      return Promise.resolve()
    } else {
      return Promise.reject('Passwords do not match.')
    }
  }

  const buildToken = () => {
    return `${encodeURI(form.getFieldValue('name'))}:${btoa(form.getFieldValue('password'))}`
  }

  const validatePool = () => {
    setError(null)
    fetch(encodeURI(`/api/v1/pool?token=${buildToken()}`), {
      credentials: 'same-origin',
      headers: authorizationHeaders
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw(response);
      }
    })
    .then(body => {
      login(body)
    })
    .catch(() => {
      setError('Authentication failed. Please try again.')
      setButtonDisabled(true)
    })
  }

  const login = (pool) => {
    if (userState && userState.pools.findIndex(p => p.id === pool.id) === -1) {
      setUserState({
        ...userState,
        pools: [...userState.pools, pool]
      })
    }
    handlePoolChange(pool)
  }

  const createPool = () => {
    setButtonDisabled(true)
    setError(null)

    const body = {
      name: form.getFieldValue('name'),
      password: btoa(form.getFieldValue('password'))
    }

    fetch(`/api/v1/pools`, {
      method: "POST",
      body: JSON.stringify(body),
      credentials: 'same-origin',
      headers: authorizationHeaders,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw(response);
      }
    })
    .then(body => {
      successToast(`${body.name} created.`)
      login(body)
    })
    .catch((error) => {
      setError(error.statusText)
    });
  }

  return (
    <div className={removeWhiteSpace(WC_NAME)}>
      <div className="background-image bracket-form-background"/>
      <div className="pool-form">
        <div className="form-container white-box-shadow">
          <h1 className={removeWhiteSpace(WC_NAME)}>{`${create ? 'Create' : 'Enter'} a pool`}</h1>
          {create
            ? <>
              <p className="header-description">Create a pool and invite others to play.</p>
              <p className="header-description">Already have a pool? <Link to='/pools?tab=enter'>Enter an existing pool.</Link></p>
            </>
            : <>
              <p className="header-description">Log in to enter a pool.</p>
              {bracketPeriod !== BracketPeriod.Closed && <p className="header-description">Don't have a pool yet? <Link to="/pools?tab=create">Create a
                pool.</Link></p>}
            </>
          }
          {create && !isAuthenticated
            ? <SignInMessage
              title="You must be signed in to create a pool."
              description="Accounts are free and allow you to manage your pool."
            />
            : <Form
              form={form}
              layout="vertical"
              initialValues={{ name: '', password: '' }}
              onFieldsChange={() => setButtonDisabled(!form.isFieldsTouched(true) || form.getFieldsError().some(field => field.errors.length > 0))}
              requiredMark="optional"
            >
              <Form.Item
                label="Pool name"
                name="name"
                rules={[{ required: true }, { validator: validateName }]}
              >
                <Input autoFocus placeholder="Pool name"/>
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true }]}
              >
                <Input id="password-field" type="password" placeholder="Password"/>
              </Form.Item>
              {create && <Form.Item
                label="Re-enter password"
                name="password2"
                rules={[{ required: true }, { validator: validatePassword }]}
              >
                <Input type="password" placeholder="Re-enter password"/>
              </Form.Item>}
              {error && <p className="error">{error}</p>}
              <Button onClick={() => create ? createPool() : validatePool()} disabled={buttonDisabled} type="primary" htmlType="submit">
                {create ? 'Create' : 'Enter'}
              </Button>
            </Form>
          }
        </div>
      </div>
    </div>
  )
}
