import { OPEN_DATE, SECOND_OPEN_DATE, TIME_ZONE, WC_NAME } from '../utils/Constants'
import { removeWhiteSpace, saveLocalBracket } from '../utils/utils'
import { BracketPeriod } from '../contexts/RootContext'
import BracketFormBracket from './BracketFormBracket'
import { useToast } from '../contexts/ToastContext'
import BracketSavedModal from './BracketSavedModal'
import React, { useEffect, useState } from 'react'
import PageLoading from '../misc/PageLoading'
import Unavailable from '../Error/Unavailable'
import GroupForm from './GroupForm'
import { Form, Modal } from 'antd'
import NameForm from './NameForm'
import Countdown from '../misc/Countdown'

const BRACKET_KEY = 'bracket-progress'
const NAME_KEY = 'name-progress'
const EMAIL_KEY = 'email-progress'
const PHONE_KEY = 'phone-progress'
const STEP_KEY = 'step-progress'

const initializeBracket = (poolId) => {
  const cachedBracket = JSON.parse(localStorage.getItem(BRACKET_KEY)) ?? {}
  return { ...cachedBracket, pool: poolId ?? null }
}

const initSecondChanceBracket = (scorecard, bracket, setBracket, setSecondChanceReady) => {
  let secondChanceReady = true
  const newBracket = { ...bracket }
  for (let i = 1; i < 17; i++) {
    const key = `pick_${i}`
    const pick = scorecard[key]
    newBracket[key] = pick
    if (!pick) {
      secondChanceReady = false
    }
  }
  setBracket(newBracket)
  setSecondChanceReady(secondChanceReady)
}

export default function BracketForm(props) {
  const {
    editing,
    updateBracket,
    the18,
    poolId,
    bracketPeriod,
    bracketsLoaded,
    setBracketsLoaded,
    authorizationHeaders,
    userState,
    setUserState,
  } = props

  const [existingBracketNames, setExistingBracketNames] = useState([])
  const [bracket, setBracket] = useState(editing ?? initializeBracket(poolId))
  const [secondChanceReady, setSecondChanceReady] = useState(false)
  const [scorecard, setScorecard] = useState(null)
  const [submitted, setSubmitted] = useState(null)
  const [rankings, setRankings] = useState(null)
  const [loading, setLoading] = useState(true)
  const [validScore, setValidScore] = useState(false)
  const [secondChanceConfirm, setSecondChanceConfirm] = useState(false)
  const [name, setName] = useState(editing ? '' : localStorage.getItem(NAME_KEY) ?? '')
  const [email, setEmail] = useState(editing ? '' : localStorage.getItem(EMAIL_KEY) ?? '')
  const [phone, setPhone] = useState(the18 ? localStorage.getItem(PHONE_KEY) : '')
  const [step, setStep] = useState(JSON.parse(editing ? 3 : localStorage.getItem(STEP_KEY)) ?? 1)

  const { errorToast } = useToast()
  const [form] = Form.useForm()

  useEffect(() => {
    fetch(`/api/v1/scorecard/current${poolId ? `?pool=${poolId}` : ''}`, {
      credentials: 'same-origin',
      headers: authorizationHeaders,
    })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw(response);
          }
        })
        .then(body => {
          setScorecard(body.scorecard)
          setRankings(body.rankings)
          setExistingBracketNames(body.brackets)
          if (bracketPeriod === BracketPeriod.SecondChance) {
            initSecondChanceBracket(body.scorecard, bracket, setBracket, setSecondChanceReady)
          }
          setLoading(false)
        })
        .catch(error => {
          errorToast(`${error.status} (${error.statusText})`)
          setLoading(false)
        })
  }, [])

  useEffect(() => {
    if (!editing) localStorage.setItem(BRACKET_KEY, JSON.stringify(bracket))
  }, [bracket])

  useEffect(() => {
    if (!editing && name) localStorage.setItem(NAME_KEY, name)
  }, [name])

  useEffect(() => {
    if (!editing && email) localStorage.setItem(EMAIL_KEY, email)
  }, [email])

  useEffect(() => {
    if (the18 && phone) localStorage.setItem(PHONE_KEY, phone)
  }, [phone])

  useEffect(() => {
    if (!editing) localStorage.setItem(STEP_KEY, JSON.stringify(step))
  }, [step])

  if (bracketPeriod === BracketPeriod.Preview) {
    return <div className="bracket-period-lock">
      <Unavailable
        title={<Countdown text="Brackets open" endDate={OPEN_DATE.computer} />}
        subTitle={`Brackets for ${WC_NAME} can be created beginning ${OPEN_DATE.human} ${TIME_ZONE}.`}
      />
    </div>
  } else if (![BracketPeriod.Open, BracketPeriod.SecondChance].includes(bracketPeriod)) {
    return <div className="bracket-period-lock">
      <Unavailable
        title="The bracket period is now closed"
        subTitle={bracketPeriod === BracketPeriod.Group
          ? `Second chance brackets open ${SECOND_OPEN_DATE.human} ${TIME_ZONE} at the the end of the Group Stage.`
          : 'Stay tuned for updates on the 2023 Women\'s tournament in Australia/ New Zealand!'
        }
      />
    </div>
  } else if (bracketPeriod === BracketPeriod.SecondChance && !secondChanceReady) {
    if (!scorecard || loading) {
      return <div className={removeWhiteSpace(WC_NAME)}>
        <div className="background-image bracket-form-background"/>
        <PageLoading />
      </div>
    }
    return <div className="bracket-period-lock">
      <Unavailable
        title="Final results from the group stage are being updated now"
        subTitle="Second chance brackets can be created soon. Please wait a moment then refresh the page and try again."
      />
    </div>
  }

  if (submitted) {
    return <div className={removeWhiteSpace(WC_NAME)}>
      <div className="background-image bracket-form-background saved"/>
      <BracketSavedModal the18={the18} bracket={submitted} />
    </div>
  }

  if (!scorecard || loading) {
    return <div className={removeWhiteSpace(WC_NAME)}>
      <div className="background-image bracket-form-background"/>
      <PageLoading />
    </div>
  }

  const validateUniqueName = (_rule, value) => {
    if (existingBracketNames.find(name => name.toLowerCase() === value.toLowerCase())) {
      return Promise.reject('A bracket with this name already exists.')
    } else {
      return Promise.resolve()
    }
  }

  const validateBracket = () => {
    const picks = Object.values(bracket)
    return picks.length === (editing ? 42 : 35) && picks.every(x => x !== undefined) && validScore
  }

  const handleChange = (value, key) => {
    setBracket(bracket => ({ ...bracket, [key]: value }))
  }

  const finalizeSubmit = () => {
    setLoading(true)
    const body = { bracket, name }
    if (email) body.email = email
    if (phone) body.phone = phone

    fetch(`/api/v1/brackets`, {
      method: "POST",
      body: JSON.stringify(body),
      credentials: 'same-origin',
      headers: authorizationHeaders,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw(response);
      }
    })
    .then(body => {
      if (bracketsLoaded) {
        setBracketsLoaded(false)
      }
      saveLocalBracket(body.id)
      localStorage.removeItem(BRACKET_KEY)
      localStorage.removeItem(NAME_KEY)
      localStorage.removeItem(EMAIL_KEY)
      the18 && localStorage.removeItem(PHONE_KEY)
      localStorage.removeItem(STEP_KEY)
      if (userState) {
        setUserState({
          ...userState,
          brackets: [...userState.brackets, body]
        })
      }
      setSubmitted(body)
    })
    .catch(error => {
      setLoading(false)
      return error.json()
      .then(message => {
        errorToast(`${error.status} (${message.error})`)
      })
    });
  }

  const handleSubmit = () => {
    if (!the18 && bracketPeriod === BracketPeriod.SecondChance) {
      setSecondChanceConfirm(true)
    } else {
      finalizeSubmit()
    }
  }

  const CurrentStep = (
    <>
      {
        step === 1 && <NameForm
          the18={the18}
          name={name}
          email={email}
          phone={phone}
          form={form}
          scorecard={scorecard}
          setName={setName}
          setEmail={setEmail}
          setPhone={setPhone}
          setStep={setStep}
          validateUniqueName={validateUniqueName}
        />
      }
      {
        step === 2 && <GroupForm
          editing={!!editing}
          bracket={bracket}
          rankings={rankings}
          scorecard={scorecard}
          setBracket={setBracket}
          setStep={setStep}
        />
      }
      {
        step === 3 && <BracketFormBracket
          the18={the18}
          editing={!!editing}
          updateBracket={updateBracket}
          bracket={bracket}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          rankings={rankings}
          scorecard={scorecard}
          setBracket={setBracket}
          setStep={setStep}
          setValidScore={setValidScore}
          validateBracket={validateBracket}
        />
      }
    </>
  )

  return (
    <div className={removeWhiteSpace(WC_NAME)}>
      <div className="background-image bracket-form-background"/>
      {CurrentStep}
      <Modal
        title="Confirm bracket submission"
        className="confirm-bracket-modal"
        visible={secondChanceConfirm}
        onCancel={() => setSecondChanceConfirm(false)}
        onOk={finalizeSubmit}
        okText="Submit"
        cancelText="Keep editing"
      >
        <p>Are you sure you're ready to submit your bracket? Second chance brackets cannot be edited once submitted.</p>
      </Modal>
    </div>
  )
}
