import { Button, Result } from 'antd'

import React from 'react'
import { useNavigate } from "react-router-dom";

export default function ServerError() {
    const navigate = useNavigate()

    return (
        <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong."
            extra={<Button onClick={() => navigate('/')} type="primary">Back Home</Button>}
        />
    )
}
