import { useAuthContext } from './contexts/AuthContext'
import { useRootContext } from './contexts/RootContext'
import { fetchLocalBrackets } from './utils/utils'
import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import { Input, Table } from 'antd'

const columns = [
  {
    title: 'Rank',
    dataIndex: 'rank',
    key: 'rank',
    className: 'ranking',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.rank - b.rank,
    width: 35,
  }, {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    className: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
    width: 100,
  }, {
    title: 'Score',
    dataIndex: 'score',
    key: 'score',
    className: 'score-total wc-primary',
    sorter: (a, b) => b.score - a.score,
    width: 35,
  }, {
    title: 'Possible',
    dataIndex: 'possible_points',
    key: 'possible',
    className: 'total',
    render: (points, _bracket, _idx) => `(${points})`,
    sorter: (a, b) => b.possible_points - a.possible_points,
    width: 35,
  }, {
    title: 'Winner',
    dataIndex: 'flag',
    key: 'flag',
    render: (flag, _bracket, idx) => (
      <img key={`flag-${idx}`} className="thumbnail" src={flag} alt={flag}/>
    ),
    sorter: (a, b) => a.flag.localeCompare(b.flag),
    width: 35,
  }
];

export default function Leaderboard(props) {
  const { fetchThe18Leaderboard, the18, brackets, name, readOnly, loading, pagy, archive } = props
  const { fetchLeaderboard } = useRootContext()
  const localBrackets = fetchLocalBrackets()
  const { userState } = useAuthContext()
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const secondChance = name === "Second Chance"

  const disableLink = (bracket) => readOnly && !localBrackets.includes(bracket.id)
    && !userState?.brackets.find(ub => ub.id === bracket.id)

  const handleClick = (bracket) => {
      navigate(`${the18 ? '/the18' : ''}/brackets/${bracket.id}`)
  }

  const paginationProps = {
    defaultPageSize: 100,
    pageSizeOptions: [20, 50, 100, 200],
    current: pagy?.page ?? 1,
    hideOnSinglePage: Boolean(pagy?.count ?? 0 < 100),
    total: pagy?.count ?? 0,
    pageSize: pagy?.items,
  }

  const handleChange = (pagination, _, sort) => {
    if ((pagy?.count ?? 0) < 100) return
    const field = sort.field === 'flag' ? 'pick_winner' : sort.field
    if (the18) {
      fetchThe18Leaderboard(secondChance, search, pagination.current, pagination.pageSize, field, sort.order)
    } else {
      fetchLeaderboard(secondChance, search, pagination.current, pagination.pageSize, field, sort.order)
    }
  }

  const handleSearch = (input) => {
    if (the18) {
      fetchThe18Leaderboard(secondChance, input === '' ? '' : search)
    } else {
      fetchLeaderboard(secondChance, input === '' ? '' : search)
    }
  }

  return (
    <div>
      <Table
        rowKey={(bracket) => bracket.id}
        rowClassName={(bracket, _idx) => disableLink(bracket) ? 'disable-link' : 'enable-link'}
        onRow={(bracket, _idx) => {
          return { onClick: () => !disableLink(bracket) && handleClick(bracket) }
        }}
        title={() => <>
          <h2 className="wc-secondary">{name}</h2>
          {!archive && <Input.Search
            allowClear
            value={search}
            placeholder="Search by bracket name"
            onChange={e => {
              setSearch(e.target.value)
              if (e.target.value === '') {
                handleSearch('')
              }
            }}
            onPressEnter={handleSearch}
            onSearch={handleSearch}
          />}
          </>}
        locale={{ emptyText: 'No brackets yet' }}
        size="small"
        loading={loading}
        style={{ maxWidth: '100%' }}
        className="leaderboard-table"
        columns={columns}
        dataSource={brackets}
        pagination={paginationProps}
        onChange={handleChange}
      />
    </div>
  )
}
