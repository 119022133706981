import { faAnglesRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import { Button, Card } from 'antd'
import React from 'react'

export default function Tile(props) {
  const { icon, title, content, actions } = props
  const navigate = useNavigate()

  return (
    <Card
      className="welcome-card"
      title={<>
        <FontAwesomeIcon className="card-icon wc-primary" icon={icon} />
        <h2 className="wc-font wc-primary">{title}</h2>
      </>}
      actions={actions.map(action =>
        <Button type="" shape="round" onClick={() => navigate(action.path)}>
          {action.title}
          <FontAwesomeIcon className="button-icon wc-secondary" icon={faAnglesRight} />
        </Button>
      )}
    >
      <p>{content}</p>
    </Card>
  )
}
