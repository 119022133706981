import React, { createContext, useContext } from 'react'
import { message } from 'antd'

const messageArray = ['success', 'info', 'warning', 'error']

const valueObject = () => {
    message.config({ duration: 5, top: 60 })

    const obj = {
        successToast: () => null,
        infoToast: () => null,
        warningToast: () => null,
        errorToast: () => null,
    }

    messageArray.forEach((i) => Object.assign(obj, { [`${i}Toast`]: (m) => message[i](m) }))

    return obj
}

const ToastContext = createContext(valueObject())

export const ToastProvider = ({ children }) => {
    return <ToastContext.Provider value={valueObject()}>{children}</ToastContext.Provider>
}

export function useToast() {
    return useContext(ToastContext)
}
