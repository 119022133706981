import { useRootContext } from '../contexts/RootContext'
import { useAuthContext } from '../contexts/AuthContext'
import { useToast } from '../contexts/ToastContext'
import { Form, Input, Modal } from 'antd'
import React, { useState } from 'react'

export default function PoolPasswordModal({ visible, setVisible }) {
  const [error, setError] = useState(null)

  const { authorizationHeaders } = useAuthContext()
  const { successToast } = useToast()
  const { pool } = useRootContext()
  const [form] = Form.useForm()

  const validatePassword = (_rule, value) => {
    const password = form.getFieldValue('password')
    if (password === value) {
      return Promise.resolve()
    } else {
      return Promise.reject('Passwords do not match.')
    }
  }

  const submitForm = () => {
    setError(null)
    fetch(`/api/v1/pools/${pool.id}`, {
      method: "PATCH",
      body: JSON.stringify({ password: btoa(form.getFieldValue('password')) }),
      credentials: 'same-origin',
      headers: authorizationHeaders,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw(response);
      }
    })
    .then(() => {
      successToast('Password changed successfully')
      setVisible(false)
    })
    .catch(() => {
      setError('Password could not be changed at this time.')
    });
  }

  return (
    <Modal
      title={`Change password for ${pool.name}`}
      className="pool-password-modal"
      visible={visible}
      onCancel={() => setVisible(false)}
      okText="Change"
      onOk={submitForm}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ password: '' }}
        requiredMark="optional"
      >
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true }]}
        >
          <Input autoFocus type="password" placeholder="Password"/>
        </Form.Item>
        <Form.Item
          label="Re-enter password"
          name="password2"
          rules={[{ required: true }, { validator: validatePassword }]}
        >
          <Input type="password" placeholder="Re-enter password"/>
        </Form.Item>
        {error && <p className="error">{error}</p>}
      </Form>

    </Modal>
  )
}
