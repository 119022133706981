import React from 'react'

export default function TeamTile(props) {
  const { team, onClick, isFirst, isSecond } = props
  const { flag, name, rank } = team

  const buildClass = () => {
    let className = 'group-team'
    if (isFirst) className += ' first-pick'
    if (isSecond) className += ' second-pick'
    return className
  }

  return (
    <div className={buildClass()} onClick={onClick}>
      {isFirst && <span className="group-selection">1st</span>}
      {isSecond && <span className="group-selection">2nd</span>}
      <img className="inline-flag" src={flag} alt={'flag'} />
      <span className="wc-secondary">{name}</span>
      <span className="wc-ranking">{rank ? `Rank: ${rank}` : ''}</span>
    </div>
  )
}
