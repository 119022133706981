import { BracketPeriod, getBracketPeriod } from '../contexts/RootContext'
import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";

export const setTitle = (title = 'World Cup') => {
  document.title = `${title} Bracket Challenge`
}

const defaultDescription = 'An app that lets you create your own bracket for the Women\'s World Cup ' +
  'tournament in Australia New Zealand. Start a private pool and compete with your friends!'

const setDescription = (description = defaultDescription) => {
  document.querySelector('meta[name="description"]').setAttribute('content', description);
}

const setIndexing = (indexing) => {
  let robots = document.querySelector('meta[name="robots"]')
  if (robots && !indexing) {
    robots.setAttribute('content', 'noindex')
  } else if (!indexing) {
    robots = document.createElement('meta')
    robots.name = 'robots'
    robots.content = 'noindex'
    document.getElementsByTagName('head')[0].appendChild(robots);
  } else if (robots) {
    robots.remove()
  }
}

export default function Seo() {
  const { pathname, search } = useLocation();
  const bracketPeriod = getBracketPeriod()

  useEffect(() => {
    if (pathname.includes('brackets')) {
      setIndexing(false)
      setDescription('Completed bracket that shows correct and incorrect picks with realtime score updates.')
      return // set bracket SEO in Bracket.jsx
    }

    if (pathname.endsWith('/manage')) {
      setIndexing(true)
      setDescription('Manage the brackets in your pool.')
      setTitle('Manage pool |')
      return
    }

    if (pathname.includes('/edit')) {
      setIndexing(false)
      setDescription('Edit your bracket before the tournament begins.')
      setTitle('Edit bracket |')
      return
    }

    switch (pathname) {
      case '/':
        setIndexing(true)
        setDescription(defaultDescription)
        setTitle()
        break

      case '/faq':
        setIndexing(true)
        setDescription('Frequently asked questions, rules, links, and contact info.')
        setTitle('FAQ |')
        break

      case '/leaderboard':
        setIndexing(true)
        setDescription('Table view of all the brackets with realtime score updates.')
        setTitle('Leaderboard |')
        break

      case '/create':
        setIndexing([BracketPeriod.Open, BracketPeriod.SecondChance].includes(bracketPeriod))
        setDescription('Create your own bracket for the 2023 tournament in Australia New Zealand!')
        setTitle('Create bracket |')
        break

      case '/pools':
        setIndexing(true)
        const action = search.includes('enter') ? 'Join' : 'Create'
        setDescription(`${action} a pool to follow the Australia New Zealand  games while competing with friends and colleagues!`)
        setTitle(`${action} pool |`)
        break

      case '/terms':
        setIndexing(true)
        setDescription()
        setTitle('Terms of service |')
        break

      case '/privacy':
        setIndexing(true)
        setDescription()
        setTitle('Privacy policy |')
        break

      case '/archive/qatar':
        setIndexing(true)
        setDescription('2022 brackets from the Men\'s tournament in Qatar.')
        setTitle('(2022) Qatar |')
        break

      case '/archive/france':
        setIndexing(true)
        setDescription('2019 brackets from the Women\'s tournament in France.')
        setTitle('(2019) France |')
        break

      case '/admin':
        setIndexing(false)
        setDescription()
        setTitle('Admin |')
        break

      case '/stats':
        setIndexing([BracketPeriod.Group, BracketPeriod.Closed].includes(bracketPeriod))
        setDescription('Statistics amassed from the thousands of brackets created by users all over the world, available once the tournament begins.')
        setTitle('Stats |')
        break

      case '/metrics':
        setIndexing(false)
        setDescription()
        setTitle('Metrics |')
        break

      default:
        setIndexing(false)
        setDescription(defaultDescription)
        setTitle('Not found |')
    }
  }, [pathname, search]);

  return null;
}