import { useNavigate } from 'react-router-dom'
import React from 'react'

export default function BackButton({ onClick, text }) {
  const navigate = useNavigate()

  return (
    <p onClick={onClick ?? (() => navigate(-1))} className="back-button">
      <i className="fa fa-angle-left"/>
      <span>{text ?? 'Back'}</span>
    </p>
  )
}
