import { BracketPeriod, useRootContext } from '../contexts/RootContext'
import { useAuthContext } from '../contexts/AuthContext'
import SelectionTable from './Charts/SelectionTable'
import React, { useEffect, useState } from 'react'
import { removeWhiteSpace } from '../utils/utils'
import WinnerTree from './Charts/WinnerTree'
import PageLoading from '../misc/PageLoading'
import { WC_NAME } from '../utils/Constants'
import Forbidden from '../Error/Forbidden'

export default function Stats() {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [data, setData] = useState([])
  const [secondChanceData, setSecondChanceData] = useState([])
  const [count, setCount] = useState([])
  const [secondChanceCount, setSecondChanceCount] = useState([])

  const { authorizationHeaders, isAdmin } = useAuthContext()
  const { bracketPeriod } = useRootContext()
  const canView = isAdmin || [BracketPeriod.Group, BracketPeriod.Closed].includes(bracketPeriod)

  useEffect(() => {
    if (canView) {
      fetch(encodeURI('/api/v1/stats'), {
        credentials: 'same-origin',
        headers: authorizationHeaders,
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw(response);
        }
      })
      .then(body => {
        setCount(body.count)
        setData(body.data)
        setSecondChanceCount(body.second_chance_count)
        setSecondChanceData(body.second_chance_data)
        setLoading(false)
      })
      .catch(error => {
        setError(error)
      })
    } else {
      setLoading(false)
    }
  }, [canView])

  if (loading || isAdmin === null) {
    return <PageLoading />
  }

  if (!canView || error) {
    return <Forbidden />
  }

  return (
    <div className={`stats-container ${removeWhiteSpace(WC_NAME)}`}>
      <h1 className="wc-primary">Stats</h1>
      <WinnerTree data={data} />
      <SelectionTable count={count} data={data} />
      {(isAdmin || bracketPeriod === BracketPeriod.Closed) && <SelectionTable count={secondChanceCount} data={secondChanceData} secondChance />}
    </div>
  )
}
