import LeaderboardDescription from '../Home/LeaderboardDescription'
import { useAuthContext } from '../contexts/AuthContext'
import { useRootContext } from '../contexts/RootContext'
import React, { useEffect, useState } from 'react'
import AdSense, { AdSlots } from '../misc/AdSense'
import { removeWhiteSpace } from '../utils/utils'
import Leaderboard from "../Leaderboard";
import Error from "../Error/Error";

export default function Archive({ tournament }) {
  const [loading, setLoading] = useState(true)
  const [brackets, setBrackets] = useState([])
  const [pagy, setPagy] = useState({})
  const [secondChance, setSecondChance] = useState([])
  const [secondChancePagy, setSecondChancePagy] = useState({})
  const [error, setError] = useState(null)

  const { authorizationHeaders } = useAuthContext()
  const { pool } = useRootContext()

  const fetchLeaderboard = (secondChance, search = '', page = 1, pageSize = 100, sort = 'rank', order = 'ascend') => {
    fetch(encodeURI(`/api/v1/leaderboard/${tournament}${pool ? `?pool=${pool.id}&` : '?'}page=${page}&pageSize=${pageSize}&sort=${sort}&order=${order}&search=${search}&secondChance=${secondChance}`), {
      credentials: 'same-origin',
      headers: authorizationHeaders,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw(response);
      }
    })
    .then(body => {
      setBrackets(body.primary)
      setPagy(body.primary_pagy)
      setSecondChance(body.second_chance)
      setSecondChancePagy(body.second_chance_pagy)
      setLoading(false)
    })
    .catch(() => {
      setError({ status: 503 })
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchLeaderboard()
  }, [])

  if (error) {
    return <Error error={error} />
  }

  return (
    <div className={removeWhiteSpace(tournament)}>
      <div className="background-image leaderboard-background"/>
      <AdSense slot={AdSlots.LeaderboardHeader} />
      <div className="leaderboard-container">
        <div className="header-container white-box-shadow">
        <h1 className="leaderboard wc-font wc-primary">{`${tournament} Leaderboard`}</h1>
        <div className="header-description wc-font">
          <LeaderboardDescription />
        </div>
      </div>
      {pool?.image_url && <div>
        <img className="pool-logo" src={pool.image_url} alt="pool logo"/>
      </div>}
        <div className="double-leaderboard">
          <Leaderboard fetchThe18Leaderboard={fetchLeaderboard} loading={loading} name="Brackets" brackets={brackets} pagy={pagy}/>
          <Leaderboard fetchThe18Leaderboard={fetchLeaderboard} loading={loading} name="Second Chance" brackets={secondChance} pagy={secondChancePagy}/>
        </div>
      </div>
      <AdSense slot={AdSlots.LeaderboardFooter1} />
      <AdSense slot={AdSlots.LeaderboardFooter2} />
      <AdSense slot={AdSlots.LeaderboardFooter3} />
    </div>
  )
}
