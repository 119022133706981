export const removeWhiteSpace = (string) => {
    return string.replaceAll(/\s+/g, '')
}

export const saveLocalBracket = (id) => {
    let ids = [id]
    const savedBrackets = localStorage.getItem('wcba-saved-brackets')
    if (savedBrackets) {
        let existingIds = []
        try {
            existingIds = JSON.parse(savedBrackets)
            ids = ids.concat(existingIds)
        } catch (error) {
            console.error(error)
        }
    }
    localStorage.setItem('wcba-saved-brackets', JSON.stringify(ids))
}

export const fetchLocalBrackets = () => {
    let savedIds = []
    try {
        const savedBrackets = localStorage.getItem('wcba-saved-brackets')
        if (savedBrackets) {
            savedIds = JSON.parse(savedBrackets)
        }
    } catch (error) {
        console.error(error)
    }

    return Array.isArray(savedIds) ? savedIds : []
}

export const copyToClipboard = async (text, setCopied) => {
    await navigator.clipboard.writeText(text)
    setCopied(true)
}

