import { BracketPeriod, getBracketPeriod, RootContextProvider } from './contexts/RootContext'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import BracketFormContainer from './BracketForm/BracketFormContainer'
import TermsAndConditions from './Faq/TermsAndConditions'
import AuthContextProvider from './contexts/AuthContext'
import { ToastProvider } from './contexts/ToastContext'
import BracketEditForm from './Bracket/BracketEditForm'
import The18Redirects from './The18/The18Redirects'
import PrivacyPolicy from './Faq/PrivacyPolicy'
import The18Logging from './The18/The18Logging'
import ShowBracket from './Bracket/ShowBracket'
import The18Create from './The18/The18Create'
import ScrollToTop from './utils/ScrollToTop'
import ManagePool from './Pools/ManagePool'
import { WC_NAME } from './utils/Constants'
import The18Home from './The18/The18Home'
import NotFound from './Error/NotFound'
import Callback from './utils/Callback'
import Archive from './Archive/Archive'
import France from './Archive/France'
import Metrics from './Stats/Metrics'
import MainMenu from './MainMenu'
import Pools from './Pools/Pools'
import Stats from './Stats/Stats'
import Welcome from './Welcome'
import Home from './Home/Home'
import Seo from './utils/Seo'
import Footer from './Footer'
import Admin from './Admin'
import Faq from './Faq/Faq'
import React from 'react'

const allowThe18 = () => {
  if (process.env.NODE_ENV === 'development') {
    return Boolean(parseInt(localStorage.getItem('qa-the-18')))
  } else {
    return location.origin.includes('heroku')
  }
}

const The18App = () => {
  return (
    <div className="the-18">
      <The18Logging />
      <Routes>
        <Route path='/' element={<The18Redirects />} />
        <Route path='/create' element={<The18Create />} />
        <Route path='/leaderboard' element={
          <The18Home tournament={getBracketPeriod() === BracketPeriod.Preview ? 'Qatar 2022' : WC_NAME}/>
        } />
        <Route path='/leaderboard/qatar' element={<The18Home tournament="Qatar 2022" />} />
        <Route path='/brackets/:id' element={<ShowBracket />} />
        <Route path='/*' element={<NotFound the18 />} />
      </Routes>
    </div>
  )
}

const MainApp = () => {
  return (
    <AuthContextProvider>
      <RootContextProvider>
        <Seo />
        <ScrollToTop />
        <MainMenu />
        <div className="main-routes-container">
          <Routes>
            <Route path='/' element={<Welcome />} />
            <Route path='/faq' element={<Faq />} />
            <Route path='/leaderboard' element={<Home />} />
            <Route path='/create' element={<BracketFormContainer />} />
            <Route path='/edit/:id' element={<BracketEditForm />} />
            <Route path='/pools' element={<Pools />} />
            <Route path='/pools/:id/manage' element={<ManagePool />} />
            <Route path='/brackets/:id' element={<ShowBracket />} />
            <Route path='/terms' element={<TermsAndConditions />} />
            <Route path='/privacy' element={<PrivacyPolicy />} />
            <Route path='/archive' element={<Navigate to="france"/>} />
            <Route path='/archive/france' element={<France />} />
            <Route path='/archive/qatar' element={<Archive tournament="Qatar 2022" />} />
            <Route path='/admin' element={<Admin />} />
            <Route path='/stats' element={<Stats />} />
            <Route path='/metrics' element={<Metrics />} />
            <Route path='/callback' element={<Callback />} />
            <Route path='/*' element={<NotFound/>} />
          </Routes>
        </div>
        <Footer />
      </RootContextProvider>
    </AuthContextProvider>
  )
}

const App = () => {
  return (
    <BrowserRouter>
      <ToastProvider>
        <Routes>
          {allowThe18() && <Route path="/the18/*" element={<The18App/>}/>}
          <Route path='/*' element={<MainApp />} />
        </Routes>
      </ToastProvider>
    </BrowserRouter>
  )
}

export default App
