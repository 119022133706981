import { faTowerBroadcast } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { countries } from '../utils/Countries'
import React from 'react'

const getTeamName = name => {
  if (window.innerWidth < 415) {
    return countries[name] ?? name
  } else {
    return name
  }
}

export default function Matchup(props) {
  const { bracket, team1, team2, round, championship } = props

  const { second_chance, scorecard } = bracket
  const { eliminated } = scorecard

  const checkClass = (team) => {
    const { name, label: pick, side } = team

    const finals = [scorecard.pick_f1, scorecard.pick_f2]

    if (name === scorecard[pick]) {
      return "correct-pick"
    } else if (name === 'no entry') {
      return "no-entry"
    } else if (eliminated.includes(name)
      || (scorecard[pick] && name !== scorecard[pick])
      || side && !side.includes(name)
      || (pick === 'pick_third' && finals.includes(name))
      || (pick === 'pick_winner' && !finals.includes(name) && !finals.includes(null))) {
      return "incorrect-pick"
    } else {
      return ""
    }
  }

  const checkPoints = (round, team) => {
    const match = team.name === scorecard[team.label]

    switch (round) {
      case 1:
        return match ? second_chance ? '' : '+2' : ''

      case 2:
        return match ? second_chance ? '+2' : '+5' : ''

      case 3:
        return match ? second_chance ? '+4' : '+10' : ''

      case 4:
        return match ? second_chance ? '+8' : '+20' : ''

      case 'third':
        return match ? second_chance ? '+12' : '+20' : ''

      case 'winner':
        return match ? second_chance ? '+16' : '+50' : ''

      default:
        return ''
    }
  }

  return (
    <ul className={`matchup ${championship ? 'championship current' : ''}`}>
      {scorecard[team1?.label] && <div className="correct-team-name-upper">{scorecard[team1?.label]}</div>}
      {team1.standings && !scorecard[team1?.label] && <div className="standings-team-name-upper">
        <FontAwesomeIcon className="standings-icon" icon={faTowerBroadcast} />
        <strong>{`${team1.standings.position}: `}</strong>
        {getTeamName(team1.standings.name)}
      </div>}
      <li className="team team-top">
        <img className="inline-flag" src={`/flags/${team1.name}.png`} alt={'flag'} />
        <span className={`team-name ${checkClass(team1)}`}>{countries[team1.name] ?? team1.name}</span>
        <span className="score">{checkPoints(round, team1)}</span>
      </li>
      {team2 && (<>
        <li className="team team-top">
          <img className="inline-flag" src={`/flags/${team2.name}.png`} alt={'flag'} />
          <span className={`team-name ${checkClass(team2)}`}>{countries[team2.name] ?? team2.name}</span>
          <span className="score">{checkPoints(round, team2)}</span>
        </li>
        {scorecard[team2?.label] && <div className="correct-team-name-lower">{scorecard[team2?.label]}</div>}
        {team2.standings && !scorecard[team2?.label] && <div className="standings-team-name-lower">
          <FontAwesomeIcon className="standings-icon" icon={faTowerBroadcast} />
          <strong>{`${team2.standings.position}: `}</strong>
          {getTeamName(team2.standings.name)}
        </div>}
      </>)}
    </ul>
  )
}
