import { Result } from 'antd'
import React from 'react'

export default function LeaderboardAlert({ message }) {

  return (
    <div className="error-background leaderboard-alert">
      <Result
        status="warning"
        title=""
        subTitle={message}
      />
    </div>
  )
}
