import { useAuthContext } from '../contexts/AuthContext'
import { useRootContext } from '../contexts/RootContext'
import BracketForm from './BracketForm'
import React from 'react'

export default function BracketFormContainer() {
  const { bracketsLoaded, bracketPeriod, pool, setBracketsLoaded } = useRootContext()
  const { authorizationHeaders, userState, setUserState } = useAuthContext()

  return <BracketForm
    poolId={pool?.id}
    bracketPeriod={bracketPeriod}
    bracketsLoaded={bracketsLoaded}
    setBracketsLoaded={setBracketsLoaded}
    authorizationHeaders={authorizationHeaders}
    userState={userState}
    setUserState={setUserState}
  />
}
