import { BracketPeriod, getBracketPeriod } from '../contexts/RootContext'
import { faPenToSquare, faTowerBroadcast } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuthContext } from '../contexts/AuthContext'
import React, { useEffect, useMemo } from 'react'
import AdSense, { AdSlots } from '../misc/AdSense'
import { removeWhiteSpace } from "../utils/utils"
import PickScore from '../BracketForm/PickScore'
import BackButton from '../misc/BackButton'
import { setTitle } from '../utils/Seo'
import Matchup from './Matchup'
import { Tooltip } from 'antd'

export default function Bracket({ bracket, standings, showBackButton }) {
  const { userState } = useAuthContext()
  const navigate = useNavigate()
  const location = useLocation()
  const { name, scorecard } = bracket
  const { dates } = scorecard
  const canEdit = getBracketPeriod() === BracketPeriod.Open && userState?.brackets.find(ub => ub.id === bracket.id)
  const isThe18 = location.pathname.includes('the18')

  useEffect(() => setTitle(`${name} |`), [name])

  const left = useMemo(() => {
    const l = []
    for (let i = 1; i <= 8; i++) {
      l.push(scorecard[`pick_${i}`] ?? bracket[`pick_${i}`])
    }
    return l
  }, [bracket, scorecard])

  const right = useMemo(() => {
    const r = []
    for (let i = 9; i <= 16; i++) {
      r.push(scorecard[`pick_${i}`] ?? bracket[`pick_${i}`])
    }
    return r
  }, [bracket, scorecard])

  const buildSide = list => {
    return list.map(slot => scorecard[`pick_${slot}`] ?? bracket[`pick_${slot}`])
  }

  return (
    <div className={removeWhiteSpace(scorecard.name)}>
      <div className="background-image bracket-form-background"/>
      <header className="bracket-header">
      {showBackButton && <BackButton text={isThe18 ? 'Leaderboard' : undefined} onClick={isThe18 ? () => navigate('/the18') : undefined}/>}
      <div className="header-text">
        <p className="bracket-name wc-quaternary">
          {name}
          {canEdit && <Tooltip placement="bottom" title="Click to edit bracket.">
            <span className="edit-bracket-icon" onClick={() => navigate(`/edit/${bracket.id}`)}>
              <FontAwesomeIcon className="wc-quaternary" icon={faPenToSquare} />
            </span>
          </Tooltip>}
        </p>
        <p className="total-score">{bracket.score}</p>
        <p className="possible-score">{bracket.possible_points}</p>
      </div>
      </header>
      <section id="bracket">
      <div className="container widescreen">
        <div className="split split-one">
        <div className="round round-one current">
          <div className="round-details wc-font wc-secondary">Round of 16<br/><span className="date wc-quaternary">{dates.sixteen}</span></div>
          <Matchup
          bracket={bracket}
          round={1}
          team1={{ name: bracket.pick_1, label: 'pick_1', standings: standings?.pick_1 }}
          team2={{ name: bracket.pick_2, label: 'pick_2', standings: standings?.pick_2 }}
          />
          <Matchup
          bracket={bracket}
          round={1}
          team1={{ name: bracket.pick_3, label: 'pick_3', standings: standings?.pick_3 }}
          team2={{ name: bracket.pick_4, label: 'pick_4', standings: standings?.pick_4 }}
          />
          <Matchup
          bracket={bracket}
          round={1}
          team1={{ name: bracket.pick_5, label: 'pick_5', standings: standings?.pick_5 }}
          team2={{ name: bracket.pick_6, label: 'pick_6', standings: standings?.pick_6 }}
          />
          <Matchup
          bracket={bracket}
          round={1}
          team1={{ name: bracket.pick_7, label: 'pick_7', standings: standings?.pick_7 }}
          team2={{ name: bracket.pick_8, label: 'pick_8', standings: standings?.pick_8 }}
          />
        </div> {/*<!-- END ROUND ONE -->*/}

        <div className="round round-two current">
          <div className="round-details wc-font wc-secondary">Quarter Finals<br/><span className="date wc-quaternary">{dates.quarter}</span></div>
          <Matchup
          bracket={bracket}
          round={2}
          team1={{ name: bracket.pick_q1, label: 'pick_q1', side: buildSide([1, 2]) }}
          team2={{ name: bracket.pick_q2, label: 'pick_q2', side: buildSide([3, 4]) }}
          />
          <Matchup
          bracket={bracket}
          round={2}
          team1={{ name: bracket.pick_q3, label: 'pick_q3', side: buildSide([5, 6]) }}
          team2={{ name: bracket.pick_q4, label: 'pick_q4', side: buildSide([7, 8]) }}
          />
        </div> {/*<!-- END ROUND TWO -->*/}

        <div className="round round-three current">
          <div className="round-details wc-font wc-secondary">Semi Finals<br/><span className="date wc-quaternary">{dates.semi}</span></div>
          <Matchup
          bracket={bracket}
          round={3}
          team1={{ name: bracket.pick_s1, label: 'pick_s1', side: buildSide([1, 2, 3, 4]) }}
          team2={{ name: bracket.pick_s2, label: 'pick_s2', side: buildSide([5, 6, 7, 8]) }}
          />
        </div> {/*<!-- END ROUND THREE -->*/}
        </div>

        <div className="champion">
        <div className="semis-l">
          <div className="round-details wc-font wc-secondary">Winner<br/><span className="date wc-quaternary">{dates.final}</span></div>
          <Matchup
          bracket={bracket}
          championship
          round={'winner'}
          team1={{ name: bracket.pick_winner, label: 'pick_winner' }}
          />
        </div>
        <span className="winner-flag-container" >
          <img className="winner-flag" src={`/flags/${bracket.pick_winner}.png`} alt={bracket.pick_winner}/>
        </span>
        <div className="final">
          {(bracket.winner_score || bracket.loser_score)
            ? <PickScore bracket={bracket} readonly />
            : <div className="trophy-img-container">
              <img className="trophy-img" src="/images/trophy.png" alt="World Cup trophy"/>
            </div>
          }
          <div className="round-details wc-font wc-secondary">Final<br/></div>
          <Matchup
          bracket={bracket}
          championship
          round={4}
          team1={{ name: bracket.pick_f1, label: 'pick_f1', side: left }}
          team2={{ name: bracket.pick_f2, label: 'pick_f2', side: right }}
          />
        </div>
        <div className="semis-r">
          <div className="round-details wc-font wc-secondary">3rd Place Winner<br/><span className="date wc-quaternary">{dates.third}</span></div>
          <Matchup
          bracket={bracket}
          championship
          round={'third'}
          team1={{ name: bracket.pick_third, label: 'pick_third' }}
          />
        </div>
        </div>

        <div className="split split-two">
        <div className="round round-three current">
          <div className="round-details wc-font wc-secondary">Semi Finals<br/><span className="date wc-quaternary">{dates.semi}</span></div>
          <Matchup
          bracket={bracket}
          round={3}
          team1={{ name: bracket.pick_s3, label: 'pick_s3', side: buildSide([9, 10, 11, 12]) }}
          team2={{ name: bracket.pick_s4, label: 'pick_s4', side: buildSide([13, 14, 15, 16]) }}
          />
        </div> {/*<!-- END ROUND THREE -->*/}

        <div className="round round-two current">
          <div className="round-details wc-font wc-secondary">Quarter Finals<br/><span className="date wc-quaternary">{dates.quarter}</span></div>
          <Matchup
          bracket={bracket}
          round={2}
          team1={{ name: bracket.pick_q5, label: 'pick_q5', side: buildSide([9, 10]) }}
          team2={{ name: bracket.pick_q6, label: 'pick_q6', side: buildSide([11, 12]) }}
          />
          <Matchup
          bracket={bracket}
          round={2}
          team1={{ name: bracket.pick_q7, label: 'pick_q7', side: buildSide([13, 14]) }}
          team2={{ name: bracket.pick_q8, label: 'pick_q8', side: buildSide([15, 16]) }}
          />
        </div> {/*<!-- END ROUND TWO -->*/}

        <div className="round round-one current">
          <div className="round-details wc-font wc-secondary">Round of 16<br/><span className="date wc-quaternary">{dates.sixteen}</span></div>
          <Matchup
          bracket={bracket}
          round={1}
          team1={{ name: bracket.pick_9, label: 'pick_9', standings: standings?.pick_9 }}
          team2={{ name: bracket.pick_10, label: 'pick_10', standings: standings?.pick_10 }}
          />
          <Matchup
          bracket={bracket}
          round={1}
          team1={{ name: bracket.pick_11, label: 'pick_11', standings: standings?.pick_11 }}
          team2={{ name: bracket.pick_12, label: 'pick_12', standings: standings?.pick_12 }}
          />
          <Matchup
          bracket={bracket}
          round={1}
          team1={{ name: bracket.pick_13, label: 'pick_13', standings: standings?.pick_13 }}
          team2={{ name: bracket.pick_14, label: 'pick_14', standings: standings?.pick_14 }}
          />
          <Matchup
          bracket={bracket}
          round={1}
          team1={{ name: bracket.pick_15, label: 'pick_15', standings: standings?.pick_15 }}
          team2={{ name: bracket.pick_16, label: 'pick_16', standings: standings?.pick_16 }}
          />
        </div> {/*<!-- END ROUND ONE -->*/}
        </div>
      </div>
      </section>
      {standings && <div className="standings-hint">
        <FontAwesomeIcon className="standings-icon" icon={faTowerBroadcast} />
        Current group standings
      </div>}
      {!isThe18 && <AdSense slot={AdSlots.BracketFooter} />}
    </div>
  )
}
