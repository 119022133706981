import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faCheck, faDownload, fa2 } from '@fortawesome/free-solid-svg-icons'
import { useAuthContext } from '../contexts/AuthContext'
import { useRootContext } from '../contexts/RootContext'
import { useToast } from '../contexts/ToastContext'
import React, { useEffect, useState } from 'react'
import SignInMessage from '../misc/SignInMessage'
import { Button, Modal, Table } from 'antd'
import { useAuth0 } from '@auth0/auth0-react'
import PageLoading from '../misc/PageLoading'
import { useParams } from 'react-router-dom'
import { WC_NAME } from '../utils/Constants'
import BackButton from '../misc/BackButton'
import { CSVLink } from 'react-csv'
import Error from '../Error/Error'
import { format } from 'date-fns'

const paginationProps = {
  defaultPageSize: 20,
  pageSizeOptions: [10, 20, 50, 100, 200],
  showSizeChanger: true,
}

export default function ManagePool() {
  const [downloaded, setDownloaded] = useState(false)
  const [deletion, setDeletion] = useState(null)
  const [brackets, setBrackets] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [name, setName] = useState('')

  const { authorizationHeaders } = useAuthContext()
  const { errorToast, successToast } = useToast()
  const { setBracketsLoaded } = useRootContext()
  const { isAuthenticated } = useAuth0()
  const { id } = useParams()

  useEffect(() => {
    if (isAuthenticated) {
      fetch(`/api/v1/pools/${id}`, {
        credentials: 'same-origin',
        headers: authorizationHeaders,
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw(response);
        }
      })
      .then(body => {
        setName(body.name)
        setBrackets(body.brackets)
        setLoading(false)
      })
      .catch((error) => {
        setError(error)
        setLoading(false)
      });
    }
  }, [])

  const handleDelete = () => {
    if (deletion) {
      const cache = deletion
      setDeletion(null)

      fetch(`/api/v1/brackets/${deletion.id}`, {
        method: "DELETE",
        credentials: 'same-origin',
        headers: authorizationHeaders,
      })
      .then(response => {
        if (response.ok) {
          // no content
          successToast(`Successfully deleted ${cache.name} from ${name}`)
          const newBrackets = brackets.filter(bracket => bracket.id !== cache.id)
          setBrackets(newBrackets)
          setBracketsLoaded(false)
        } else {
          throw(response);
        }
      })
      .catch(error => {
        errorToast(`${error.status} (${error.statusText})`)
      });
    }
  }

  const columns = [
    {
      key: 'name',
      title: 'Bracket name',
      ellipsis: { showTitle: false },
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (entry, obj) => <>{entry}{obj.second_chance && <FontAwesomeIcon className="second-chance-icon" icon={fa2} />}</>
    }, {
      key: 'email',
      title: 'Email',
      ellipsis: { showTitle: false },
      render: (entry) => <div data-private="lipsum">
        {entry.user_email || '—'}
      </div>,
      sorter: (a, b) => {
        if (a.user_email && b.user_email) a.user_email.localeCompare(b.user_email)
        else if (a.user_email) return -1
        else if (b.user_email) return 1
        else return 0
      },
    }, ...name === 'The 18'
      ? [{
        key: 'phone',
        title: 'Phone',
        ellipsis: { showTitle: false },
        render: (entry) => <div data-private="lipsum">
          {entry.phone || '—'}
        </div>,
        sorter: (a, b) => {
          if (a.phone && b.phone) a.phone.localeCompare(b.phone)
          else if (a.phone) return -1
          else if (b.phone) return 1
          else return 0
        },
      }]
      : [], {
      key: 'date',
      title: 'Created',
      ellipsis: { showTitle: false },
      render: (entry) => {
        if (entry.created_at) return format(new Date(entry.created_at), 'MMM do p')
        return format(new Date(), 'MM/dd/yyyy')
      },
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
    }, {
      key: 'actions',
      title: 'Actions',
      width: 90,
      render: (entry) => <div className="action-icons">
        <FontAwesomeIcon onClick={() => setDeletion(entry)} className="danger" icon={faTrashCan} />
      </div>
    }
  ]

  const Contents = () => {
    if (!isAuthenticated) return (
      <SignInMessage
        signInOnly
        title="Please sign in to continue."
        description="You must be signed in to manage this pool."
      />
    )

    if (loading) return <PageLoading />
    if (error) return <Error error={error}/>

    return <>
      <h1>{`Manage ${name}`}</h1>
      <Table
        rowKey="id"
        dataSource={brackets}
        columns={columns}
        pagination={brackets.length > 20 ? paginationProps : false}
      />
      <div className="second-chance-hint"><FontAwesomeIcon className="second-chance-icon" icon={fa2} /> - denotes second chance bracket</div>
      <CSVLink
        data={brackets}
        target="_blank"
        onClick={() => setDownloaded(true)}
        filename={`${name.replace(' ', '_')}-${Date.now()}.csv`}
      >
        <Button className="csv-download">
          Download CSV
          {downloaded ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faDownload}/>}
        </Button>
      </CSVLink>
    </>
  }

  return (
    <div className={`${WC_NAME} manage-pool-container`}>
      <BackButton />
      <Contents />
      <Modal
        title={`Confirm deletion of ${deletion?.name}`}
        className="delete-bracket-modal"
        visible={!!deletion}
        onCancel={() => setDeletion(null)}
        onOk={handleDelete}
        okText="Delete"
        cancelText="Cancel"
        okButtonProps={{ danger: true }}
      >
        <p>{`Are you sure you want to remove ${deletion?.name} from ${name}?`}</p>
        <p>Deleting this bracket is permanent and cannot be undone.</p>
      </Modal>
    </div>
  )
}
