import React, { useEffect } from 'react'
import FormTile from './FormTile'

export default function MatchupForm(props) {
  const { bracket, disabled, handleChange, mobileRight, team1, team2, championship } = props

  useEffect(() => {
    const pick = bracket[team1.field]

    if (pick && team1.options.findIndex(item => item.name === pick) === -1)
      handleChange(undefined, team1.field)
  }, [team1.options])

  useEffect(() => {
    if (team2) {
      const pick = bracket[team2.field]
      if (pick && team2.options.findIndex(item => item.name === pick) === -1)
        handleChange(undefined, team2.field)
    }
  }, [team2?.options])

  return (
    <ul className={`matchup ${championship ? 'championship current' : ''}`}>
      <li className="team team-form team-top">
        <FormTile
          disabled={disabled}
          mobileRight={mobileRight}
          field={team1.field}
          label={team1.label}
          onChange={handleChange}
          options={team1.options}
          value={bracket[team1.field]}
        />
      </li>
      {team2 && (
        <li className="team team-form team-top">
          <FormTile
            disabled={disabled}
            mobileRight={mobileRight}
            field={team2.field}
            label={team2.label}
            onChange={handleChange}
            options={team2.options}
            value={bracket[team2.field]}
          />
        </li>
      )}
    </ul>
  )
}
