import { useNavigate } from 'react-router-dom'
import PageLoading from '../misc/PageLoading'
import React, { useEffect } from 'react'

const key = 'login-redirect-path'
export const setLoginRedirect = (path) => {
  localStorage.setItem(key, path)
}

export default function Callback() {
  const navigate = useNavigate()

  useEffect(() => {
    const path = localStorage.getItem(key) ?? '/'
    localStorage.removeItem(key)
    navigate(path)
  }, [])

  return <PageLoading />
}
