import { useAuthContext } from '../contexts/AuthContext'
import { useRootContext } from '../contexts/RootContext'
import React, { useEffect, useState } from 'react'
import Leaderboard from "../Leaderboard";
import Error from "../Error/Error";

export default function France() {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const [cdhBrackets, setCdhBrackets] = useState([])
    const [productionBrackets, setProductionBrackets] = useState([])

    const { authorizationHeaders } = useAuthContext()
    const { pool } = useRootContext()

    useEffect(() => {
        if (pool?.name !== 'FWC Production') {
            setError({ status: 403 })
            return
        }
        fetch('/api/v1/leaderboard/France%202019', {
            credentials: 'same-origin',
            headers: authorizationHeaders,
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw(response);
                }
            })
            .then(body => {
                setCdhBrackets(body.primary)
                setProductionBrackets(body.second_chance)
                setLoading(false)
            })
            .catch(error => {
                setError(error)
                setLoading(false)
            })
    }, [])

    if (error) {
        return <Error error={error} />
    }

    return (
        <div className="leaderboard-container France2019">
            <h1 className="leaderboard wc-font wc-primary">France 2019 Leaderboard</h1>
            <div className="double-leaderboard">
                <Leaderboard archive loading={loading} name="CDH" brackets={cdhBrackets} />
                <Leaderboard archive loading={loading} name="Production" brackets={productionBrackets} />
            </div>
        </div>
    )
}
