import { Button, Result } from 'antd'

import React from 'react'
import { useNavigate } from "react-router-dom";

export default function NotFound({ the18 }) {
    const navigate = useNavigate()

    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button onClick={() => navigate(the18 ? '/the18' : '/')} type="primary">Back Home</Button>}
        />
    )
}
