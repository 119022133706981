export const WC_NAME = 'Australia New Zealand 2023'
export const OPEN_DATE = {
    computer: '01 June 2023 00:01:00 UTC+10',
    human: 'June 1st at 12:01am'
}
export const CLOSE_DATE = {
    computer: '20 July 2023 17:00:00 UTC+10',
    human: 'July 20th at 5:00pm'
}
export const SECOND_OPEN_DATE = {
    computer: '03 August 2023 22:05:00 UTC+10',
    human: 'August 3rd at 10:05pm'
}
export const SECOND_CLOSE_DATE = {
    computer: '05 August 2023 15:00:00 UTC+10',
    human: 'August 5th at 3:00pm'
}
export const TIME_ZONE = 'Australian Eastern Standard Time (UTC+10)'
export const ABBREV_TIME_ZONE = 'AEST'
