import { Treemap } from '@ant-design/plots'
import React, { useMemo } from 'react'

export default function WinnerTree({ data }) {

  const treeData = useMemo(() => {
    return {
      name: 'root',
      children: data.map(entry => {
        return {
          name: entry.country,
          value: entry.winner,
        }
      })
    }
  }, [data])

  const treeConfig = {
    data: treeData,
    renderer: 'svg',
    label: {
      style: {
        fill: 'yellow',
        stroke: 'black',
        lineWidth: 1,
      }
    },
    rectStyle: ({ name }) => {
      return {
        lineWidth: 4,
        stroke: 'white',
        fill: `p(a)/flags/${name}.png`
      }
    },
    legend: false,
  };

  return <Treemap {...treeConfig} />
}

