import { BracketPeriod, getBracketPeriod } from '../contexts/RootContext'
import { useNavigate } from 'react-router-dom'
import BackButton from '../misc/BackButton'
import MatchupForm from './MatchupForm'
import WinnerFlag from './WinnerFlag'
import { Button } from 'antd'
import React from 'react'
import PickScore from './PickScore'

export default function BracketFormBracket(props) {
  const {
    the18,
    bracket,
    handleChange,
    handleSubmit,
    rankings,
    scorecard,
    setStep,
    setValidScore,
    validateBracket,
    editing,
    updateBracket,
  } = props
  const navigate = useNavigate()

  const roundDetails = `round-details primary wc-font wc-secondary`
  const { dates } = scorecard

  const groupOption = (pick) => {
    return [rankings[bracket[pick]]]
  }

  const knockoutOptions = (options) => {
    const rankedOptions = []
    for (const option of options) {
      if (option) {
        rankedOptions.push(rankings[option])
      }
    }
    return rankedOptions
  }

  const thirdPlacePicks = () => {
    let opts = []
    if (bracket.pick_f1) {
      [bracket.pick_s1, bracket.pick_s2].forEach(pick => {
        if (pick && pick !== bracket.pick_f1) opts.push(rankings[pick])
      })
    }
    if (bracket.pick_f2) {
      [bracket.pick_s3, bracket.pick_s4].forEach(pick => {
        if (pick && pick !== bracket.pick_f2) opts.push(rankings[pick])
      })
    }

    return opts
  }

  return (
    <>
      <header className="bracket-header">
        <BackButton onClick={() => setStep(getBracketPeriod() === BracketPeriod.SecondChance ? 1 : 2)}
                    text={getBracketPeriod() === BracketPeriod.SecondChance ? 'Registration' : 'Group stage'}/>
        {!the18 && <div className="header-text">
          <p className="bracket-name wc-quaternary">{bracket.name}</p>
          <span className={'bracket-action-buttons'}>
            <Button disabled={!validateBracket()} type="primary"
                    onClick={editing ? () => updateBracket(bracket) : handleSubmit}>
              {editing ? 'Save changes' : 'Submit'}
            </Button>
            {editing && <Button onClick={() => navigate(`/brackets/${bracket.id}`)}>
              Discard changes
            </Button>}
          </span>
        </div>}
      </header>
      <section id="bracket">
        <div className="container widescreen">
          <div className="split split-one">
            <div className="round round-one current">
              <div className={roundDetails}>Round of 16<br/><span className="date wc-quaternary">{dates.sixteen}</span></div>
              <MatchupForm
                disabled
                bracket={bracket}
                handleChange={handleChange}
                team1={{ field: 'pick_1', label: '1A', options: groupOption('pick_1') }}
                team2={{ field: 'pick_2', label: '2B', options: groupOption('pick_2') }}
              />
              <MatchupForm
                disabled
                bracket={bracket}
                handleChange={handleChange}
                team1={{ field: 'pick_3', label: '1C', options: groupOption('pick_3') }}
                team2={{ field: 'pick_4', label: '2D', options: groupOption('pick_4') }}
              />
              <MatchupForm
                disabled
                bracket={bracket}
                handleChange={handleChange}
                team1={{ field: 'pick_5', label: '1E', options: groupOption('pick_5') }}
                team2={{ field: 'pick_6', label: '2F', options: groupOption('pick_6') }}
              />
              <MatchupForm
                disabled
                bracket={bracket}
                handleChange={handleChange}
                team1={{ field: 'pick_7', label: '1G', options: groupOption('pick_7') }}
                team2={{ field: 'pick_8', label: '2H', options: groupOption('pick_8') }}
              />
            </div> {/*<!-- END ROUND ONE -->*/}

            <div className="round round-two current">
              <div className={roundDetails}>Quarter Finals<br/><span className="date wc-quaternary">{dates.quarter}</span></div>
              <MatchupForm
                bracket={bracket}
                handleChange={handleChange}
                team1={{ field: 'pick_q1', label: 'W49', options: knockoutOptions([bracket.pick_1, bracket.pick_2]) }}
                team2={{ field: 'pick_q2', label: 'W50', options: knockoutOptions([bracket.pick_3, bracket.pick_4]) }}
              />
              <MatchupForm
                bracket={bracket}
                handleChange={handleChange}
                team1={{ field: 'pick_q3', label: 'W53', options: knockoutOptions([bracket.pick_5, bracket.pick_6]) }}
                team2={{ field: 'pick_q4', label: 'W54', options: knockoutOptions([bracket.pick_7, bracket.pick_8]) }}
              />
            </div> {/*<!-- END ROUND TWO -->*/}

            <div className="round round-three current">
              <div className={roundDetails}>Semi Finals<br/><span className="date wc-quaternary">{dates.semi}</span></div>
              <MatchupForm
                mobileRight
                bracket={bracket}
                handleChange={handleChange}
                team1={{ field: 'pick_s1', label: 'W57', options: knockoutOptions([bracket.pick_q1, bracket.pick_q2]) }}
                team2={{ field: 'pick_s2', label: 'W58', options: knockoutOptions([bracket.pick_q3, bracket.pick_q4]) }}
              />
            </div> {/*<!-- END ROUND THREE -->*/}
          </div>

          <div className="champion">
            <div className="semis-l">
              <div className={roundDetails}>Winner<br/><span className="date wc-quaternary">{dates.final}</span></div>
              <MatchupForm
                bracket={bracket}
                handleChange={handleChange}
                championship
                team1={{ field: 'pick_winner', label: 'Winner', options: knockoutOptions([bracket.pick_f1, bracket.pick_f2]) }}
              />
            </div>
            <WinnerFlag winner={bracket.pick_winner} />
            <div className="final">
              <PickScore bracket={bracket} handleChange={handleChange} setValidScore={setValidScore} />
              <div className={roundDetails}>Final<br/></div>
              <MatchupForm
                bracket={bracket}
                handleChange={handleChange}
                championship
                team1={{ field: 'pick_f1', label: 'W61', options: knockoutOptions([bracket.pick_s1, bracket.pick_s2]) }}
                team2={{ field: 'pick_f2', label: 'W62', options: knockoutOptions([bracket.pick_s3, bracket.pick_s4]) }}
              />
            </div>
            <div className="semis-r">
              <div className={roundDetails}>3rd Place Winner<br/><span className="date wc-quaternary">{dates.third}</span></div>
              <MatchupForm
                bracket={bracket}
                handleChange={handleChange}
                championship
                team1={{ field: 'pick_third', label: 'Third Place', options: thirdPlacePicks() }}
              />
            </div>
          </div>

          <div className="split split-two">
            <div className="round round-three current">
              <div className={roundDetails}>Semi Finals<br/><span className="date wc-quaternary">{dates.semi}</span></div>
              <MatchupForm
                bracket={bracket}
                handleChange={handleChange}
                team1={{ field: 'pick_s3', label: 'W59', options: knockoutOptions([bracket.pick_q5, bracket.pick_q6]) }}
                team2={{ field: 'pick_s4', label: 'W60', options: knockoutOptions([bracket.pick_q7, bracket.pick_q8]) }}
              />
            </div> {/*<!-- END ROUND THREE -->*/}

            <div className="round round-two current">
              <div className={roundDetails}>Quarter Finals<br/><span className="date wc-quaternary">{dates.quarter}</span></div>
              <MatchupForm
                mobileRight
                bracket={bracket}
                handleChange={handleChange}
                team1={{ field: 'pick_q5', label: 'W51', options: knockoutOptions([bracket.pick_9, bracket.pick_10]) }}
                team2={{ field: 'pick_q6', label: 'W52', options: knockoutOptions([bracket.pick_11, bracket.pick_12]) }}
              />
              <MatchupForm
                mobileRight
                bracket={bracket}
                handleChange={handleChange}
                team1={{ field: 'pick_q7', label: 'W55', options: knockoutOptions([bracket.pick_13, bracket.pick_14]) }}
                team2={{ field: 'pick_q8', label: 'W56', options: knockoutOptions([bracket.pick_15, bracket.pick_16]) }}
              />
            </div> {/*<!-- END ROUND TWO -->*/}

            <div className="round round-one current">
              <div className={roundDetails}>Round of 16<br/><span className="date wc-quaternary">{dates.sixteen}</span></div>
              <MatchupForm
                disabled
                mobileRight
                bracket={bracket}
                handleChange={handleChange}
                team1={{ field: 'pick_9', label: '1B', options: groupOption('pick_9') }}
                team2={{ field: 'pick_10', label: '2A', options: groupOption('pick_10') }}
              />
              <MatchupForm
                disabled
                mobileRight
                bracket={bracket}
                handleChange={handleChange}
                team1={{ field: 'pick_11', label: '1D', options: groupOption('pick_11') }}
                team2={{ field: 'pick_12', label: '2C', options: groupOption('pick_12') }}
              />
              <MatchupForm
                disabled
                mobileRight
                bracket={bracket}
                handleChange={handleChange}
                team1={{ field: 'pick_13', label: '1F', options: groupOption('pick_13') }}
                team2={{ field: 'pick_14', label: '2E', options: groupOption('pick_14') }}
              />
              <MatchupForm
                disabled
                mobileRight
                bracket={bracket}
                handleChange={handleChange}
                team1={{ field: 'pick_15', label: '1H', options: groupOption('pick_15') }}
                team2={{ field: 'pick_16', label: '2G', options: groupOption('pick_16') }}
              />
            </div> {/*<!-- END ROUND ONE -->*/}
          </div>
        </div>
      </section>
      {the18 && <div className="the18-submit-bracket-button">
        <Button disabled={!validateBracket()} type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </div>}
    </>
  )
}
