import { pickName } from './GroupForm'
import TeamTile from './TeamTile'
import { Card } from 'antd'
import React from 'react'

export default function GroupCard(props) {
  const { bracket, first, second, name, group, setBracket } = props
  const firstPick = pickName(name, 1)
  const secondPick = pickName(name, 2)

  const handleSelection = (idx) => {
    if (first && second)
      return

    const newBracket = { ...bracket }
    const pick = group[idx]
    if (!first)
      newBracket[firstPick] = pick.name
    else if (!second)
      newBracket[secondPick] = pick.name
    setBracket(newBracket)
  }

  const handleRemoval = (position) => {
    const newBracket = { ...bracket }
    if (position === 1) {
      newBracket[firstPick] = second?.name
      if (second)
        newBracket[secondPick] = undefined
    } else if (position === 2) {
      newBracket[secondPick] = undefined
    }
    setBracket(newBracket)
  }

  return (
    <Card
      key={name}
      className="group-card"
      title={<>
        <h2 className="wc-font wc-primary">{`Group ${name.toUpperCase()}`}</h2>
      </>}
    >
      {first && <TeamTile key={first.name} isFirst team={first} onClick={() => handleRemoval(1)}/>}
      {second && <TeamTile key={second.name} isSecond team={second} onClick={() => handleRemoval(2)}/>}
      {group.map((option, idx) => {
        return (
          <TeamTile key={option.name} team={option} onClick={() => handleSelection(idx)} />
        )
      })}
    </Card>
  )
}
