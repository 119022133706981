import { getBracketPeriod } from '../contexts/RootContext'
import React, { useEffect, useState } from 'react'
import { removeWhiteSpace } from '../utils/utils'
import { WC_NAME } from '../utils/Constants'
import { Leaderboards } from '../Home/Home'
import { the18AuthHeaders } from './utils'
import Error from '../Error/Error'

export default function The18Home({ tournament = WC_NAME }) {
  const [loading, setLoading] = useState(true)
  const [brackets, setBrackets] = useState([])
  const [pagy, setPagy] = useState({})
  const [secondChance, setSecondChance] = useState([])
  const [secondChancePagy, setSecondChancePagy] = useState({})
  const [error, setError] = useState(null)

  const bracketPeriod = getBracketPeriod()

  const fetchLeaderboard = (secondChance, search = '', page = 1, pageSize = 100, sort = 'rank', order = 'ascend') => {
    fetch(encodeURI(`/api/v1/leaderboard/${tournament}?pool=${process.env['THE_18_POOL_ID']}&page=${page}&pageSize=${pageSize}&sort=${sort}&order=${order}&search=${search}&secondChance=${secondChance}`), {
      credentials: 'same-origin',
      headers: the18AuthHeaders,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw(response);
      }
    })
    .then(body => {
      setBrackets(body.primary)
      setPagy(body.primary_pagy)
      setSecondChance(body.second_chance)
      setSecondChancePagy(body.second_chance_pagy)
      setLoading(false)
    })
    .catch(() => {
      setError({ status: 503 })
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchLeaderboard()
  }, [])

  return (
    <>
      <div className="background-image leaderboard-background"/>
      <div className="the18-spacer"/>
      <div className={`leaderboard-container ${removeWhiteSpace(tournament)}`}>
        {error
          ? <Error error={error} />
          : <Leaderboards
            loading={loading}
            bracketPeriod={bracketPeriod}
            primaryBrackets={brackets}
            primaryPagy={pagy}
            secondChanceBrackets={secondChance}
            secondChancePagy={secondChancePagy}
            name="Leaderboard"
            the18
            fetchThe18Leaderboard={fetchLeaderboard}
          />
        }
      </div>
      <div className="the18-spacer"/>
    </>
  )
}
