import { Column } from '@ant-design/plots'
import { colors } from '../utils'
import React from 'react'

export default function GroupedColumnStats({ colorIdx, data, title }) {

  const config = {
    data: data.sort((a, b) => a.date - b.date),
    xField: 'dateLabel',
    yField: 'count',
    seriesField: 'type',
    isGroup: true,
    slider: {
      start: .765,
      end: 1,
    },
    theme: {
      colors10: colorIdx ? colors[colorIdx] : colors
    }
  };

  return <>
    <h2>{title}</h2>
    <Column {...config} />
  </>

}
