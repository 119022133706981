import { faFutbol, faPeopleRoof, faRankingStar } from '@fortawesome/free-solid-svg-icons'
import { BracketPeriod, useRootContext } from './contexts/RootContext'
import { OPEN_DATE, WC_NAME } from './utils/Constants'
import AdSense, { AdSlots } from './misc/AdSense'
import { removeWhiteSpace } from './utils/utils'
import { useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
import Countdown from './misc/Countdown'
import Tile from './Home/Card'

export default function Welcome() {
  const navigate = useNavigate()
  const { bracketPeriod } = useRootContext()

  useEffect(() => {
    if (![BracketPeriod.Preview, BracketPeriod.Open].includes(bracketPeriod)) {
      navigate('/leaderboard')
    }
  }, [])

  return (
    <>
      <div className="background-image welcome-background"/>
      <AdSense slot={AdSlots.HomeHeader} />
      <div className={`welcome ${removeWhiteSpace(WC_NAME)}`}>
        <div className="welcome-text">
          {bracketPeriod === BracketPeriod.Preview &&
            <Countdown className="countdown" text="Brackets open" endDate={OPEN_DATE.computer} />
          }
          <h1 className="wc-font wc-primary">World Cup Bracket Challenge</h1>
          <p className="wc-font header-description">Create your own bracket for the Women's World Cup tournament in Australia and New Zealand. Start a private pool and compete with your friends!</p>
        </div>
        <div className="card-container">
          <Tile
            title="Pools"
            icon={faPeopleRoof}
            content="Create a free pool and invite your friends!"
            actions={[
              { title: 'Create a pool', path: '/pools?tab=create' },
              { title: 'Join a pool', path: '/pools?tab=enter' }
            ]}
          />
          <Tile
            title="Brackets"
            icon={faFutbol}
            content="Fill out your bracket. Pick who you think will win!"
            actions={[{ title: 'Create a bracket', path: '/create' }]}
          />
          <Tile
            title="Leaderboard"
            icon={faRankingStar}
            content="See where you stack up once play begins."
            actions={[{ title: 'Leaderboard', path: '/leaderboard' }]}
          />
        </div>
      </div>
    </>
  )
}
