import ServerError from "./ServerError";
import Unavailable from './Unavailable'
import Forbidden from "./Forbidden";
import NotFound from "./NotFound";
import React from 'react'

export default function Error({ error }) {
    switch (error.status) {
        case 403:
            return <Forbidden />
        case 404:
            return <NotFound />
        case 503:
            return <Unavailable />
        default:
            return <ServerError />
    }
}
