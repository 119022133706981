import { useRootContext } from '../contexts/RootContext'
import ShareButtons from '../utils/ShareButtons'
import React from 'react'
import { Button, Modal } from 'antd'
import {WC_NAME} from "../utils/Constants";

const title = 'World Cup Bracket Challenge'
const message = `Join my pool for the World Cup Bracket Challenge - Fill out a bracket for ${WC_NAME}!`
const hashtags = ['worldcup', 'bracketchallenge']

export default function InviteModal({ visible, setVisible }) {
  const { pool } = useRootContext()

  return (
    <Modal
      title={`Invite friends to ${pool.name}`}
      className="pool-invite-modal"
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button onClick={() => setVisible(false)}>Close</Button>
      ]}
    >
      <p>Share a direct link to your pool. Anyone with the link will be able to join.</p>
      <ShareButtons title={title} message={message} url={pool.url} hashtags={hashtags} />
    </Modal>
  )
}
